import i18n from 'i18n-js';
import moment from 'moment';
import 'moment/locale/es';

import { en, es } from './resources';

i18n.fallbacks = true;
i18n.translations = { en, es };
export const languages = [
  { name: 'Español', code: 'es-ES', key: 'es' },
  { name: 'English', code: 'en-US', key: 'en' },
];

const defaultLan = 'en';
i18n.locale = defaultLan;
moment.locale(i18n.locale);

export function t(key, values) {
  return values ? i18n.t(key, values) : i18n.t(key);
}

export const i18nConfig = (language) => {
  i18n.fallbacks = true;
  i18n.translations = { en, es };

  i18n.locale = language || defaultLan;
  moment.locale(i18n.locale);
  return language;
};

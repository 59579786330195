import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: 'en',
  termsAndConditions: false,
  user: {
    email: '',
    roles: '',
    firstName: '',
    lastName: '',
  }
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setTermsAndConditions: (state, action) => {
      state.termsAndConditions = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    reset: () => {
      return initialState;
    }
  },
});

export const { setLanguage, setTermsAndConditions, setUser, reset } = settingsSlice.actions;

export default settingsSlice.reducer;
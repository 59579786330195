import React from 'react';
import PropTypes from 'prop-types';
// import * as DimensionsUtils from '../utils/dimensions';
import { COLORS } from '../constants';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--color-white);
`;

const Button = styled.button`
  padding: 10px 30px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  border-bottom: 2px solid transparent;
  transition: ease border-bottom 250ms;
  flex: 1 1 0px;
  color: var(--color-secondary-blue);
  font-family: "BrandonGrotesqueRegular";
  font-size: var(--min-font-size);
`;

const TabButtonGroup = ({ buttons, onSelect, selected }) => {
  return (
    <Container>
      {
        buttons.map(b => (
          <Button
            key={b.value}
            active={(selected === b.value).toString()}
            onClick={() => onSelect(b.value)}
            style={selected === b.value ? styles.active : null}
            data-testid='tabButton'>
            {b.label}
          </Button>
        ))
      }
    </Container >
  );
};

TabButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(Object).isRequired,
  selected: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const styles = {
  active: {
    fontFamily: 'BrandonGrotesqueMedium',
    borderBottom: `2px solid ${COLORS.secondaryColorBlue}`,
    opacity: '1',
  },
};

export default TabButtonGroup;
import React, { useEffect, useState } from 'react';
import Select from '../components/Select';
import { t } from '../utils/translations';
import * as DimensionsUtils from '../utils/dimensions';
import { useDispatch, useSelector } from 'react-redux';
import { getRecommendations } from '../features/recommendations/recommendationsSlice';
import { getLoading as getLoadingRecommendations } from '../features/recommendations/selectors';
import { getLoading } from '../features/solutions/selectors';
import { getSolutions } from '../features/solutions/solutionsSlice';
import Loader from 'react-loader-spinner';
import Button from './ui/Button';
import { COLORS } from '../constants';
import RowSwitchControl from './ui/RowSwitchControl';
import PropTypes from 'prop-types';
import { types } from '../utils/inputsValues';
import styled from 'styled-components';
import { getWeightFields, getAgeFields, getVolumeFields, getSolutionsFields, getHospitalized, getHumanMilkAvailable, getEnoughMilk } from '../features/inputs/selector';
import { setFieldByType, setBooleanInput, resetFields } from '../features/inputs/inputsSlice';
import TermsAndConditionsModal from './TermsAndConditionsModal';

const Container = styled.div`
  font-family: 'BrandonGrotesqueRegular';
  margin-left: .2em;
  margin-right: .2em;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Row = styled.div`
  display: 'flex';
  align-items: 'center';
  height: var(--min-main-grid-height);
`;

const Separator = styled.hr`
  height: 1px;
  border-width: 0;
  margin: 0;
  color: var(--color-lightGrey);
`;

const ButtonContainer = styled.div`
  width: 90%;
  margin-top: 2vh;
  align-self: center;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.5vh;
`;

const Link = styled.a`
  color: var(--color-primary);
`;

const ButtonLoader = styled(Loader)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const getWeightValue = (weightValues) => {
  let result = 0;
  for (let name in weightValues) {
    switch (name) {
    case 'kg':
      result += weightValues[name] * 1000;
      break;
    case 'h_g':
      result += weightValues[name] * 100;
      break;
    case 'd_g':
      result += weightValues[name] * 10;
      break;
    case 'g':
      result += weightValues[name];
      break;
    }
  }
  return result;
};

export const getWeightDisplayValue = (initWeightValues) => {
  const value = getWeightValue(initWeightValues);
  return value ? `${value} g` : '';
};

export const getSimpleSelectValue = (values) => {
  let result = 0;
  for (let name in values)
    result = values[name];

  return result;
};

const GridInputs = ({ getValuesFromState }) => {
  const dispatch = useDispatch();
  const weightFields = useSelector(getWeightFields);
  const ageFields = useSelector(getAgeFields);
  const volumeFields = useSelector(getVolumeFields);
  const solutionsFields = useSelector(getSolutionsFields);
  const loading = useSelector(getLoading);
  const loadingRecommendations = useSelector(getLoadingRecommendations);
  const hospitalized = useSelector(getHospitalized);
  const humanMilkAvailable = useSelector(getHumanMilkAvailable);
  const enoughMilk = useSelector(getEnoughMilk);
  const [isTCModalOpen, setIsTCModalOpen] = useState(false);
  const resetValues = () => {
    dispatch(resetFields());
  };

  const IsEnabled = () => {
    return solutionsFields.values.solutions ||
      (
        getWeightValue(weightFields.values) > 0 &&
        getSimpleSelectValue(volumeFields.values) > 0 &&
        (hospitalized || (!hospitalized && getSimpleSelectValue(ageFields.values) > 0))
      );
  };

  const handleSubmit = () => {
    const feedingId = getSimpleSelectValue(solutionsFields.values);
    dispatch(getRecommendations({
      weight: getWeightValue(weightFields.values),
      hospitalized,
      humanMilkAvailable,
      enoughMilk,
      postConceptionAge: hospitalized ? null : getSimpleSelectValue(ageFields.values),
      fluidVolumeLimit: getSimpleSelectValue(volumeFields.values),
      feedingOfInterest: feedingId > 0 ? feedingId : null,
      feedingName: feedingId > 0 ? solutionsFields.groups.solutions.find(o => o.value === feedingId).label : '',
    }));
  };

  useEffect(() => {
    if (!getValuesFromState)
      dispatch(resetFields());

    dispatch(getSolutions());
  }, []);

  return (loading ?
    <LoadingContainer>
      <Loader type="Oval" color={COLORS.blue} height={DimensionsUtils.getValueForBreakpoint([40, 30, 20])} />
    </LoadingContainer> :
    <>
      <Container>
        <Separator />
        <Row>
          <Select type={types.weight} label={t('WEIGHT')} data-testid='selectWeight' noValueSelectedLabel='0 g'
            optionGroups={weightFields.groups} setFields={(type, values) => dispatch(setFieldByType({ type, values }))}
            valueGroups={weightFields.values}
            customGetValues={getWeightDisplayValue}
            disabled={loadingRecommendations}
          />
        </Row>
        <Separator />
        <RowSwitchControl
          label={t('HOSPITALIZED')}
          testId="switchHospitalized"
          onChange={(value) => dispatch(setBooleanInput({ type: 'hospitalized', value }))}
          checked={hospitalized}
        />
        <Separator />
        {!hospitalized && <>
          <Row data-testid='selectAgeContainer' >
            <Select type={types.age} label={t('AGE')} colSize={50} noValueSelectedLabel='0 weeks'
              optionGroups={ageFields.groups} setFields={(type, values) => dispatch(setFieldByType({ type, values }))}
              valueGroups={ageFields.values}
              disabled={loadingRecommendations}
            />
          </Row>
          <Separator />
        </>}
        <Row>
          <Select type={types.volume} label={t('VOLUME')} colSize={50} noValueSelectedLabel='0'
            optionGroups={volumeFields.groups} setFields={(type, values) => dispatch(setFieldByType({ type, values }))}
            valueGroups={volumeFields.values}
            disabled={loadingRecommendations}
          />
        </Row>
        <Separator />
        <RowSwitchControl
          label={t('HM_AVAILABLE')}
          testId="switchHumMilk"
          onChange={(value) => dispatch(setBooleanInput({ type: 'humanMilkAvailable', value }))}
          checked={humanMilkAvailable}
        />
        {humanMilkAvailable && <>
          <Separator />
          <RowSwitchControl
            label={t('ENOUGH_HM')}
            testId="swithEnoughMilk"
            onChange={(value) => dispatch(setBooleanInput({ type: 'enoughMilk', value }))}
            checked={enoughMilk}
          />
        </>}
        <Separator />
        <Row>
          <Select label={t('FEEDING')} colSize={50} type={types.solutions}
            optionGroups={solutionsFields.groups} setFields={(type, values) => dispatch(setFieldByType({ type, values }))}
            valueGroups={solutionsFields.values}
            optional
            disabled={loadingRecommendations}
          />
        </Row>
        <Separator />
      </Container>
      <ButtonContainer>
        <Button type="submit" disabled={!IsEnabled() || loadingRecommendations} onClick={() => handleSubmit()}
          data-testid='submitButton'>
          {loadingRecommendations ?
            <ButtonLoader type="Oval" color={COLORS.white} height={DimensionsUtils.getValueForBreakpoint([40, 30, 20])} /> :
            t('SUBMIT')}
        </Button>
      </ButtonContainer>
      <LinkContainer>
        <Link onClick={resetValues} data-testid="resetBtn">{t('RESET_VALUES')}</Link>
      </LinkContainer>
      <LinkContainer>
        <Link onClick={() => setIsTCModalOpen(true)} style={{ textDecoration: 'underline' }}>{t('TERMS_CONDITIONS')}</Link>
      </LinkContainer>
      <TermsAndConditionsModal isModalOpen={isTCModalOpen} setIsModalOpen={setIsTCModalOpen} />
    </>
  );
};

GridInputs.propTypes = {
  getValuesFromState: PropTypes.bool,
};

export default GridInputs;
import { createGlobalStyle } from 'styled-components';
import { COLORS } from './constants';

const GlobalStyles = createGlobalStyle`
  html {
    font-family: BrandonGrotesqueRegular;
    --color-primary: ${COLORS.primaryColor};
    --color-primary-dark: ${COLORS.primaryColorDark};
    --color-primary-light: ${COLORS.primaryColorLight};
    --color-secondary: ${COLORS.secondaryColor};
    --color-secondary-blue: ${COLORS.secondaryColorBlue};
    --color-grey: ${COLORS.grey};
    --color-grey-dark: ${COLORS.greyDark};
    --color-lightGrey: ${COLORS.lightGrey};
    --color-blue: ${COLORS.blue};
    --color-white: ${COLORS.white};
    --color-black: ${COLORS.black};
    --color-lighterGrey: ${COLORS.lighterGrey};
    --border-radius: 25px;
    --input-height: 3em;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    
    --background-gradient: linear-gradient(to bottom right, var(--color-primary-dark), var(--color-primary), var(--color-primary-dark));
    --background-header: linear-gradient(to bottom right, var(--color-primary), var(--color-primary-dark));
    --min-header-height: 7vh;
    --min-main-grid-height: 7vh;
    --header-font-size: 4.5vw;
    --min-font-size:  3.5vw;
    --label-font-size: 2.5vw;
    --title-font-size: 8.5vw;
    --reference-font-size: 2.5vw;
    --product-name-font-size: 4.5vw;

    @media (orientation: portrait) {
      --min-header-height: 7vh;
      --header-font-size: 4.5vw;
      --min-main-grid-height: 7vh;
      --min-font-size:  3.5vw;
      --label-font-size: 3.2vw;
      --title-font-size: 8.5vw;
      --product-name-font-size: 6.5vw;
      --reference-font-size: 3.5vw;



      @media (min-width: ${(props) => props.theme.breakPoints.mediumUnitPx}) {
        --min-header-height: 9vh;
        --header-font-size: 5.5vw;
        --min-main-grid-height: 8vh;
        --min-font-size:  4.5vw;
        --label-font-size: 3.5vw;
        --title-font-size: 10.5vw;
      }

      @media (min-width: ${(props) => props.theme.breakPoints.bigUnitPx}) {
        --min-header-height: 11vh;
        --header-font-size: 6.5vw;
        --min-main-grid-height: 9vh;
        --min-font-size:  4.5vw;
        --label-font-size: 3.5vw;
        --title-font-size: 12.5vw;
      }
    }

    @media (orientation: landscape) {
      --min-header-height: 4em;
      --header-font-size: 4.5vh;
      --min-main-grid-height: 7vw;
      --min-font-size:  3.5vh;
      --label-font-size: 2.5vh;
      --title-font-size: 8.5vh;

      @media (min-height: ${(props) => props.theme.breakPoints.mediumUnitPx}) {
        --min-header-height: 5em;
        --header-font-size: 5.5vh;
        --min-main-grid-height: 8vw;
        --min-font-size:  4.5vh;
        --label-font-size: 3.5vh;
        --title-font-size: 10.5vh;
      }

      @media (min-width: ${(props) => props.theme.breakPoints.bigUnitPx}) {
        --min-header-height: 6em;
        --header-font-size: 6.5vh;
        --min-main-grid-height: 9vw;
        --min-font-size:  5.5vh;
        --label-font-size: 3.5vh;
        --title-font-size: 12.5vh;
      }
    }
  }
`;

export default GlobalStyles;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as recommendationsService from '../../services/recommendations';
import { v4 as uuidv4 } from 'uuid';

import { getSolutionById as getSolutionByIdService } from '../../services/solutions';
const SLICE_KEY = 'RECOMMENDATIOS';

export const getRecommendations = createAsyncThunk(
  `${SLICE_KEY}/getRecommendations`,
  async (inputs, { getState, rejectWithValue }) => {
    try {
      let uuidAux = undefined;
      let uuid = getState().analytics.uuid;
      let regionId = getState().authentication.regionId;
      if (!uuid)
        uuidAux = uuidv4();
      const response = await recommendationsService.getRecommendations({ ...inputs, uuid: uuid || uuidAux, regionId });
      if (response.status !== 200)
        return rejectWithValue('ERROR_GETTING_RECOMMENDATIONS');

      return { ...response.data, filters: { ...inputs }, uuid: uuidAux };
    } catch (err) {
      return rejectWithValue('ERROR_GETTING_RECOMMENDATIONS');
    }
  }
);

export const getSolutionsToMix = createAsyncThunk(
  `${SLICE_KEY}/getSolutionsToMix`,
  async (ids, { rejectWithValue }) => {
    try {
      const solutions = [];
      for (let i = 0; i < ids.length; i++) {
        const response = await getSolutionByIdService(ids[i]);
        if (response.status === 200) solutions.push(response.data);
      }
      return solutions;
    } catch (err) {
      return rejectWithValue('ERROR_GETTING_SOLUTIONS_TO_MIX');
    }
  }
);

const recommendationsSlice = createSlice({
  name: 'recommendations',
  initialState: {
    loading: false,
    error: '',
    solutions: [],
    references: [],
    referenceSelected: null,
    solutionSelected: null,
    filters: {
      weight: null,
      hospitalized: false,
      humanMilkAvailable: false,
      enoughMilk: false,
      postConceptionAge: null,
      fluidVolumeLimit: null,
      feedingOfInterest: null,
      feedingName: '',
    },
    solutionsToMix: [],
  },
  reducers: {
    setReferenceSelected(state, { payload }) {
      return { ...state, referenceSelected: state.references.find(r => r.id === payload) };
    },
    setSolutionSelected(state, { payload }) {
      return { ...state, solutionSelected: state.solutions.find(r => r.id === payload) };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRecommendations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRecommendations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getRecommendations.fulfilled, (state, action) => {
      state.loading = false;
      state.solutions = action.payload.solutions;
      state.references = action.payload.references;
      state.filters = action.payload.filters;
      state.referenceSelected = action.payload.references?.length ? action.payload.references[0] : undefined;
      state.solutionSelected = action.payload.solutions?.length ? action.payload.solutions[0] : undefined;
    });
    builder.addCase(getSolutionsToMix.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSolutionsToMix.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSolutionsToMix.fulfilled, (state, action) => {
      state.loading = false;
      state.solutionsToMix = action.payload;
    });
  }
});

export const { setReferenceSelected, setSolutionSelected } = recommendationsSlice.actions;

export default recommendationsSlice.reducer;
import React from 'react';
import styled from 'styled-components';
import { t } from '../utils/translations';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
`;

const Content = styled.div`
  background-color: var(--color-white);
`;

const TextContent = styled.div`
  color: var(--color-black);
  opacity: 51%;
  padding: 10px;
  margin-left: 1em;
  margin-right: 1em;
`;

const Text = styled.div`
  text-align: justify;
  margin-top: 1em;
  margin-bottom: 1em;
  color: var(--color-primary-dark);
`;

const Bullet = styled.span`
  display:block;
  margin-left: 1em;
  margin-bottom: 0;
  margin-top: 0;
  color: var(--color-primary-dark);
  &:before{
    content: '•  ';
  }
`;
const Reference = styled.p`
  font-size: var(--reference-font-size);
`;

const TermsAndConditions = () => {
  return (
    <Container>
      <Content>

        <TextContent>
          <Text>
            Abbott Preterm Nutrition is an web-based platform developed and made available by Abbott to aid healthcare professionals in the enteral nutrition management of preterm infants in Gulf countries neonatal intensive care units during initial hospitalization and after hospital discharge.
            Healthcare professionals intending to use Similac brand products may use this web-based platform to help determine which Similac product best meets estimated nutritional requirements for low birthweight preterm infants<sup>1</sup>, very low birthweight preterm infants<sup>2</sup>, and discharged preterm infants<sup>3</sup>.
            Users of Abbott Preterm Nutrition assume full responsibility for all data entered and all conclusions related to patient management.
          </Text>
          <Bullet>The information provided in this web-based platform is for enteral and oral feedings only.</Bullet>
          <Bullet>Breast milk is best and is recommended for as long as possible during infancy.</Bullet>
          <Bullet>The information provided by Abbott Preterm Nutrition is intended to assist healthcare professionals with selection of feeding plans that help to meet preterm infant nutrient needs, and is not a substitute for healthcare professional assessment and conclusions made for an individual infant’s clinical and nutrient needs.</Bullet>
          <Bullet>This web-based platform was developed to accommodate potential feeding solutions:
            <Bullet>for preterm infants born less than 2500 grams through 41 weeks post-conceptional gestational age on full enteral feedings (beyond initial phase of enteral fluid advancement)</Bullet>
            <Bullet>for feeding volumes ranging 80-200 mL/kg/day</Bullet>
            <Bullet>by using 8 feedings in 24 hours</Bullet>
            <Bullet>for hospitalized preterm infants, 300-3600 g,  using a nutrient profile of 20 Cal/fl oz preterm human milk for calculations<sup>4</sup></Bullet>
            <Bullet>for discharged preterm infants, 1500-5500 g, using a nutrient profile of 20 Cal/fl oz term human milk for calculations<sup>5</sup></Bullet>
          </Bullet>
          <Bullet>
            The osmolality of the feedings referenced in this web-based platform are no higher than 385 mOsm/kg water. Osmolality can be markedly increased by supplements and medications added to feedings.
          </Bullet>
          <Text>Hypocaloric and hypercaloric feedings should be used under the direction of a health care professional. Abbott data on calorically dense feedings is limited.
            <Bullet>27 Cal/fl oz or more calorically dense feedings may not supply enough water for some infants. Hydration status should be monitored, and water should be supplied from other sources if necessary.</Bullet>
            <Bullet>For improved tolerance, it is best to increase caloric density slowly, by 2- to 4-Cal/fl oz increments.</Bullet>
          </Text>

          <Reference> {t('REFERENCE_1')} </Reference>
          <Reference> {t('REFERENCE_2')} </Reference>
          <Reference> {t('REFERENCE_3')} </Reference>
          <Reference> {t('REFERENCE_4')} </Reference>
          <Reference> {t('REFERENCE_5')} </Reference>
        </TextContent>

      </Content>
    </Container>
  );
};


export default TermsAndConditions;
import React, { useState } from 'react';
import Button from './ui/Button';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { t } from '../utils/translations';
import { getUserFullName } from '../features/settings/selectors';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import TermsAndConditionsModal from './TermsAndConditionsModal';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--color-grey);
  justify-content: flex-end;;
`;

const Content = styled.div`
  width: 100%;
  background: white;
`;

const Title = styled.p`
  font-family: BrandonGrotesqueMedium;
  font-size: var(--min-font-size);
  color: var(--color-primary);
  text-align: center;
  font-weight: bold;
`;

const Text = styled.div`
  margin-top: 5vh;
  margin-bottom: 5vh;
  opacity: 51%;
  padding: 10px;
  text-align: center;
  color: var(--color-primary-dark);
  font-weight: bold;
`;

const ContainerButton = styled.div`
  width: 100%;
  margin-bottom: 5vh;
  font-size: var(--min-font-size);

`;

const StyledButton = styled(Button)`
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  width: 90%;
  margin: auto;
  display: block;
`;

const StyledSVG = styled(ReactSVG)`
  margin: auto;
`;

const LinkContainer = styled.div`
  margin-top: 2vh;
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakPoints.bigUnitPx}) {
    margin-top: 0.5em;
  }
`;

const Link = styled.a`
  color: var(--color-secondary);
  cursor: pointer;
  text-decoration: underline;
`;


const WelcomeBack = ({ onOk }) => {
  const userName = useSelector(getUserFullName);
  const [isTCModalOpen, setIsTCModalOpen] = useState(false);

  return (
    <Container>
      <StyledSVG src="iconBlue.svg" />
      <Content>
        <Title>{t('WELCOME_BACK')}</Title>
        <Title>{userName}</Title>
        <Text>
          {t('WELCOME_BACK_TEXT')}
        </Text>
        <ContainerButton>
          <StyledButton data-testid='okButton' onClick={onOk}>{t('GO_TO_INPUTS')}</StyledButton>
          <LinkContainer>
            <Link onClick={() => setIsTCModalOpen(true)}>{t('TERMS_CONDITIONS')}</Link>
          </LinkContainer>
        </ContainerButton>
      </Content>
      <TermsAndConditionsModal isModalOpen={isTCModalOpen} setIsModalOpen={setIsTCModalOpen} />

    </Container>
  );
};

WelcomeBack.propTypes = {
  onOk: PropTypes.func,
};

export default WelcomeBack;
import React from 'react';
import InstallPWA from './InstallPWA';
import { t } from '../utils/translations';
import PropTypes from 'prop-types';
import Icon from '../components/Icon';
import * as DimensionsUtils from '../utils/dimensions';

import { COLORS } from '../constants';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-white);  
  min-height: 35vh;
  font-size: var(--min-font-size);
  padding: 15px;
`;

const Title = styled.p`
  font-size: var(--header-font-size);
  color: var(--color-primary-dark);
  font-family: BrandonGrotesqueMedium;
  text-align: center;
  font-weight: bold;
`;

const Label = styled.p`
  text-align: center;
  color: var(--color-primary);
  font-size: var(--min-font-size);
`;

const CloseContainer = styled.div`
  display: flex;
  flex-dirction: row;  
  justify-content: flex-end;
  width: 100%;
`;
const Onboarding = ({ closeModal }) => {
  return (
    <Container>
      <Content>
        <CloseContainer>
          <Icon
            icon="ICON-close"
            size={DimensionsUtils.getValueForBreakpoint([13, 12, 11])}
            onClick={closeModal}
            color={COLORS.lightGrey}
            data-testid='closeButton' />
        </CloseContainer>
        <Title>{t('GET_APP')}</Title>
        <img src='./assets/favicon-96x96.png' />
        <Label>{t('NO_ADDITIONAL_DOWNLOAD')}</Label>
        <Label>{t('INSTALL_APP')}</Label>
        <InstallPWA />
      </Content>
    </Container>
  );
};

Onboarding.propTypes = {
  closeModal: PropTypes.func,
};

export default Onboarding;
import api from './api';

export async function getSolutions({ active = true, simulation = false, ...props }) {
  return await api.get('/solutions', {
    params: { active, simulation, ...props },
  }, { credentials: 'include' });
}

export async function getSolutionById(id) {
  return await api.get(`/solutions/${id}`, {}, { credentials: 'include' });
}


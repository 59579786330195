import { createSlice } from '@reduxjs/toolkit';

import { getOptionsSelect, getDefaultValues, types } from '../../utils/inputsValues';


export const initialState = {
  weight: {
    groups: getOptionsSelect(types.weight, []),
    values: getDefaultValues(types.weight),
  },
  age: {
    groups: getOptionsSelect(types.age, []),
    values: getDefaultValues(types.age),
  },
  volume: {
    groups: getOptionsSelect(types.volume, []),
    values: getDefaultValues(types.volume),
  },
  solutions: {
    groups: {
      solutions: [
        { value: 0, label: 'Select Solution' },
      ]
    },
    values: { solutions: 0 },
  },
  hospitalized: false,
  humanMilkAvailable: false,
  enoughMilk: false,
};
const inputsSlice = createSlice({
  name: 'inputs',
  initialState,
  reducers: {
    setFieldByType(state, { payload }) {
      let values = [...payload.values];
      if (payload.type != types.solutions) {
        state[payload.type].groups = getOptionsSelect(payload.type, payload.values);
        let indexValues = 0;
        const optionGroups = state[payload.type].groups;
        for (const prop in optionGroups) {
          if (values[indexValues] > optionGroups[prop][optionGroups[prop].length - 1].value) {
            values[indexValues] = optionGroups[prop][0].value;
            state[payload.type].groups = getOptionsSelect(payload.type, values);
          }
          indexValues++;
        }
      }
      let index = 0;
      let newValueGroups = {};
      for (const prop in state[payload.type].values) {
        newValueGroups[prop] = values[index++];
      }
      state[payload.type].values = newValueGroups;
    },
    setSolutionsOptions(state, { payload }) {
      state.solutions.groups.solutions = [...state.solutions.groups.solutions, ...payload];
    },
    setBooleanInput(state, { payload }) {
      state[payload.type] = payload.value;
    },
    resetFields(state) {
      return { ...initialState, solutions: { groups: state.solutions.groups, values: initialState.solutions.values } };
    },
  },
});

export const { setFieldByType, setSolutionsOptions, setBooleanInput, resetFields } = inputsSlice.actions;

export default inputsSlice.reducer;
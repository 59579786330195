import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Container = styled.div`
`;

const BottomModal = styled(Modal)`
  outline: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--color-white);
  border-radius: 20px 20px 0 0;
  -webkit-box-shadow: 5px 5px 15px 5px var(--color-lightGrey); 
  box-shadow: 5px 5px 15px 5px var(--color-lightGrey);
  ${({ height }) => height ? `height: ${height}%;` : 'min-height: 50%;'}
`;

const ModalBottom = ({ isOpen, closeModal, children, height, ...props }) => {
  return (
    <Container {...props}>
      <BottomModal
        height={height}
        isOpen={isOpen}
        onRequestClose={closeModal}
        /* istanbul ignore next */
        onAfterOpen={() => {
          document.body.style.overflow = 'hidden';
          document.body.style.top = `-${window.scrollY}px`;
          document.body.style.position = 'fixed';
        }}
        /* istanbul ignore next */
        onAfterClose={() => {
          document.body.style.overflow = 'unset';
          const scrollY = document.body.style.top;
          document.body.style.position = '';
          document.body.style.top = '';
          window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }}
        ariaHideApp={false}
        style={{ overlay: { zIndex: 2 } }}
      >
        {children}
      </BottomModal>
    </Container>
  );
};

ModalBottom.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  children: PropTypes.element,
  height: PropTypes.number,
};
export default ModalBottom;
import React from 'react';
import { ReactSVG } from 'react-svg';
import Icon from '../components/Icon';
import * as DimensionsUtils from '../utils/dimensions';

import styled from 'styled-components';
import { t } from '../utils/translations';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  background: var(--background-gradient);
  align-items: center;
`;

const StyledSVG = styled(ReactSVG)`
  margin-top: 10vh;
  margin-bottom: 2vh;
  text-align: center;
  width: 30vw;
`;

const ErrorMsgContainer = styled.div`
  background-color: var(--color-secondary);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

const ErrorMsg = styled.p`
  color: var(--color-white);
`;

const WebError = () => {
  return (
    <Container>
      <StyledSVG src="icon.svg" />
      <ErrorMsgContainer>
        <Icon
          data-testid="backButton"
          icon="ICON-alert"
          size={DimensionsUtils.getValueForBreakpoint([40, 30, 20])}
          color="white" />
        <ErrorMsg>{t('NO_MOBILE_ERROR')}</ErrorMsg>
      </ErrorMsgContainer>
    </Container>
  );
};

export default WebError;
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../features/authentication/authenticationSlice';
import { getAuthenticationError, getLoading } from '../features/authentication/selectors';
import { ReactSVG } from 'react-svg';
import { getValueForBreakpoint } from '../utils/dimensions';
import { t } from '../utils/translations';
import Loader from 'react-loader-spinner';
import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import Icon from '../components/Icon';
import { COLORS } from '../constants';
import styled from 'styled-components';
import Onboarding from '../components/Onboarding';
import Modal from 'react-modal';
import { isIOS } from 'react-device-detect';


const StyledSVG = styled(ReactSVG)`
  margin-top: 10vh;
  margin-bottom: 5vh;
  text-align: center;
  `;

const LoginInput = styled(Input)`
  background-color: ${({ hasValue }) => hasValue ? 'var(--color-secondary-blue)' : 'var(--color-primary-dark)'};
  border: 1px solid var(--color-primary-dark);
  color: var(--color-white);
  font-size:16px;
`;

const Container = styled.div`
  align-items: center;
  align-self: auto;
  background: var(--color-primary);
  background-repeat: no-repeat;
  background-position: left bottom, right top;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: var(--color-secondary);
  width: 100%;
  justify-content: center;
  align-items: center;
  position:fixed;
  top:0;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Form = styled.form`
  min-width: 90%;
`;

const LabelForm = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLabel = styled.p`
  color: var(--color-white);
  text-align: center;
  margin-bottom: 0;
  font-size: var(--header-font-size);
  font-family: "BrandonGrotesqueMedium";
`;

const ContainerButton = styled.div`
  width: 100%;
  margin-top: 10vh;
`;

const StyledText = styled.p`
  color: var(--color-white);
  text-align: center;
  font-size: var(--label-font-size);
`;

const LoadindContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  height: 90%;
  text-align: center;
  margin-top: 3vh;
`;

const LinkContainer = styled.div`
  font-family: "BrandonGrotesqueMedium";
`;


const BottomModal = styled(Modal)`
  outline: none;
  position: absolute;
  right: 5%;
  left: 5%;
  bottom: 3%;
  background-color: var(--color-white);
  -webkit-box-shadow: 5px 5px 15px 5px var(--color-lightGrey); 
  box-shadow: 5px 5px 15px 5px var(--color-lightGrey);
  min-height: 95%;
  @media (orientation: portrait) {
    min-height: 85%;
    @media (min-width: ${(props) => props.theme.breakPoints.mediumUnitPx}) {
      min-height: 75%
    }
    @media (min-width: ${(props) => props.theme.breakPoints.bigUnitPx}) {
      min-height: 70%
    }
  }
`;

export default function Login() {
  const dispatch = useDispatch();
  const authError = useSelector(getAuthenticationError);
  const isLoading = useSelector(getLoading);
  const [email, setUserEmail] = useState();
  const [password, setPassword] = useState();
  const isInStandaloneMode = (('standalone' in window.navigator) && window.navigator.standalone) || window.matchMedia('(display-mode: standalone)').matches;
  const [isModalOpen, setIsModalOpen] = useState(!isInStandaloneMode);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const credentials = { email, password };
    await dispatch(login({
      credentials,
      isInStandaloneMode
    }));
  };
  return (
    <>
      <Container style={isIOS ? { paddingBottom: '20px' } : { paddingBottom: '5px' }}>
        {isLoading ?
          <LoadindContainer>
            <Loader type="Oval" color={COLORS.white} height={getValueForBreakpoint([80, 60, 40])} />
          </LoadindContainer> :
          <>
            {authError &&
              <ErrorContainer style={isIOS ? { paddingTop: '35px' } : { paddingTop: '0px' }}>
                <Icon
                  icon="ICON-alert"
                  size={getValueForBreakpoint([50, 40, 30])}
                  color="white" />
                <LabelContainer>
                  <StyledText style={authError === 'USER_PASS_ERROR' ? { marginBottom: 0 } : null}>{t(authError)}</StyledText>
                  {authError === 'USER_PASS_ERROR' && <StyledText style={{ marginTop: 0 }}>{t('FORGOT_PASS')}</StyledText>}
                </LabelContainer>
              </ErrorContainer>
            }
            <StyledSVG src="icon.svg" style={isIOS ? { marginTop: '14vh' } : { marginTop: '10vh' }} />
            <Form onSubmit={handleSubmit} data-testid="formLogin">
              <LabelForm>
                <StyledLabel>{t('USER')}</StyledLabel>
                <LoginInput type="email" hasValue={email && email !== ''} onChange={(e) => setUserEmail(e.target.value)} disabled={isLoading} testId="inputEmail" />
              </LabelForm>
              <LabelForm>
                <StyledLabel>{t('PASSWORD')}</StyledLabel>
                <LoginInput type="password" hasValue={password && password !== ''} onChange={(e) => setPassword(e.target.value)} disabled={isLoading} testId="inputPassword" />
              </LabelForm>
              <ContainerButton>
                <Button type="submit" disabled={isLoading || !email || !password}>{t('SIGN_IN')}</Button>
              </ContainerButton>
            </Form>
          </>
        }
        <LinkContainer>
          <StyledText>{t('FORGOT_PASS')}</StyledText>
        </LinkContainer>
        <BottomModal
          isOpen={isModalOpen}
          /* istanbul ignore next */
          onAfterOpen={() => document.body.style.overflow = 'hidden'}
          /* istanbul ignore next */
          onAfterClose={() => document.body.style.overflow = 'unset'}
          ariaHideApp={false}
        >
          <Onboarding closeModal={() => setIsModalOpen(false)} data-testid='onboardingCtrl' />
        </BottomModal>
      </Container >
    </>
  );
}
import React from 'react';
import Button from './ui/Button';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { t } from '../utils/translations';
import { getUserFullName } from '../features/settings/selectors';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import TermsAndConditions from './TermsAndConditions';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  background-color: var(--color-grey);
`;

const Content = styled.div`
  background-color: var(--color-white);
  margin-top: 250px;
`;

const Title = styled.p`
  font-family: BrandonGrotesqueMedium;
  font-size: var(--header-font-size);
  color: var(--color-primary);
  text-align: center;
  font-weight: bold;
`;


const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2vh;
  margin-top: 3vh;
`;

const StyledButton = styled(Button)`
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  width: 90%;
`;


const StyledSVG = styled(ReactSVG)`
  padding-top: 10vh;
  padding-bottom: 5vh;
  position: fixed;
  width: 100%;
  text-align: center;
  background-color: var(--color-grey);
  z-index: 1;
`;


const Welcome = ({ onAccept }) => {
  const userName = useSelector(getUserFullName);
  return (
    <Container>
      <StyledSVG src="iconBlue.svg" />
      <Content>
        <Title>{t('WELCOME')}</Title>
        {/* TODO: get name from state */}
        <Title>{userName}</Title>
        <TermsAndConditions />
        <ContainerButton>
          <StyledButton onClick={onAccept}>{t('ACCEPT')}</StyledButton>
        </ContainerButton>
      </Content>
    </Container>
  );
};

Welcome.propTypes = {
  onAccept: PropTypes.func,
};

export default Welcome;
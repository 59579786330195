import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as solutionsService from '../../services/solutions';
import { setSolutionsOptions } from '../inputs/inputsSlice';
const SLICE_KEY = 'SOLUTIONS';

export const getSolutions = createAsyncThunk(
  `${SLICE_KEY}/getSolutions`,
  async (inputs, { dispatch, rejectWithValue }) => {
    try {
      const response = await solutionsService.getSolutions({ ...inputs });
      if (response.status !== 200)
        return rejectWithValue('ERROR_GETTING_SOLUTIONS');

      dispatch(setSolutionsOptions(response.data.map(s => { return { value: s.id, label: s.name }; }) || []));
      return response.data;
    } catch (err) {
      return rejectWithValue('ERROR_GETTING_SOLUTIONS');
    }
  }
);
const solutionsSlice = createSlice({
  name: 'solutions',
  initialState: {
    loading: false,
    error: '',
    items: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSolutions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSolutions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSolutions.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload;
    });
  }
});

export default solutionsSlice.reducer;
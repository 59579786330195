const en = {
  USER: 'User',
  PASSWORD: 'Password',
  SIGN_IN: 'Sign in',
  FORGOT_PASS: 'Please contact the administrator if you forgot your password',
  INSERT_VALUES: 'Please, complete all fields to continue',
  UNEXPECTED_ERROR: 'Unexpected error',
  USER_PASS_ERROR: 'User or password not valid, please try again.  ',
  GO_BACK_INPUTS: 'Go back to inputs',
  CONNECTION_FAIL: 'Connection Failed',
  INTERNAL_ERROR: 'Internal Error',
  GO_BACK: 'Go Back',
  TITLE_ERROR_MSG: 'We are sorry',
  APP_NAME: 'ABBOT PRETERM NUTRITION',
  INPUTS: "Baby's condition",
  PARTS: 'parts',
  INSTALL_APP: 'With our web-based platform you can get quick and easy access on the go!',
  GET_APP: 'Get the Web-based platform!',
  NO_ADDITIONAL_DOWNLOAD: 'No additional download required.',
  SESSION_EXPIRED: 'The session has expired, please login again.',
  REFERENCE_1: '1. Klein CJ. Nutrient requirements for preterm infant formulas. J Nutr. 2002;132(6 Suppl 1):1395S-577S. doi:10.1093/jn/132.6.1395S',
  REFERENCE_2: '2. Koletzko 2021, Poindexter B, Uauy R (eds): Nutritional Care of Preterm Infants: Scientific Basis and Practical Guidelines. World Rev Nutr Diet. Basel, Karger, 2014, vol 110, pp 297-299 (DOI: 10.1159/000360195) ',
  REFERENCE_3: "3. Lapillonne A, O'Connor DL, Wang D, Rigo J. Nutritional recommendations for the late-preterm infant and the preterm infant after hospital discharge. J Pediatr. 2013;162(3 Suppl):S90-S100. doi:10.1016/j.jpeds.2012.11.058",
  REFERENCE_4: '4. Meeting the Special Needs of Low-Birth-Weight and Premature Infants in the Hospital (A8100). Columbus, Ohio: Abbott Nutrition, Abbott Laboratories, January 1998, p. 56.',
  REFERENCE_5: '5. American Academy of Pediatrics Committee on Nutrition. In: Kleinman RE, ed. Pediatric Nutrition Handbook, 4th ed. American Academy of Pediatrics, Elk Grove Village, IL. 1998:655.',
  LOGOUT: 'Logout',
  UPDATE_APP: 'Update web-based platform',
  UPDATE_APP_TEXT: 'An update is required to keep using the web-based platform. Please, tap on "Update web-based platform" button',
  RETURN_SUMMARY: 'Return to summary of inputs screen',
  USER_ROLE_ERROR: 'Invalid user',
};
const es = {
  USER: 'Usuario',
  PASSWORD: 'Contraseña',
  SIGN_IN: 'Ingresar',
  FORGOT_PASS: 'Contacte al administrador en caso de no recordar su contraseña',
  INSERT_VALUES: 'Por favor, ingrese todos los campos antes de continuar',
  UNEXPECTED_ERROR: 'Se ha producido un error inesperado. Intente nuevamente',
  USER_PASS_ERROR: 'Usuario o password incorrector. Intente nuevamente. ',
  GO_BACK_INPUTS: 'Volver a planilla de valores',
  CONNECTION_FAIL: 'Falla en la conexión',
  INTERNAL_ERROR: 'Error Interno',
  GO_BACK: 'Volver',
  TITLE_ERROR_MSG: 'Lo sentimos',
  APP_NAME: 'ABBOT PRETERM NUTRITION',
  INPUTS: 'Condición del bebe',
  PARTS: 'partes',
  INSTALL_APP: 'Instale esta aplicación para un rapido y fácil acceso.',
  GET_APP: 'Instala la aplicación',
  NO_ADDITIONAL_DOWNLOAD: 'No se requiere una descarga adicional.',
  SESSION_EXPIRED: 'La sesión ha expirado.  Por favar inicie sesión nuevamente.',
  LOGOUT: 'Cerrar Sesión',
  UPDATE_APP: 'Actualizar aplicación',
  UPDATE_APP_TEXT: 'Se requiere actualizar la aplicación. Haga click en el botón "Actualizar aplicación"',
  RETURN_SUMMARY: 'Volver al resumen',
  USER_ROLE_ERROR: 'Usuario inhabilitado',
};

export { en, es };
import React from 'react';
import ModalBottom from '../components/ui/ModalBottom';
import Button from '../components/ui/Button';
import PropTypes from 'prop-types';
import { t } from '../utils/translations';
import * as DimensionsUtils from '../utils/dimensions';

import styled from 'styled-components';
import TermsAndConditions from './TermsAndConditions';

const Container = styled.div`
  display:flex;
  padding: 15px;
  flex-direction: column;
  height: 90%;
  overflow: auto;
`;

const Header = styled.div`
  flex: 1;
`;

const Title = styled.p`
  margin: 0;
  margin-top: 2vh;
  font-size: var(--header-font-size);
  font-family: BrandonGrotesqueMedium;
  color: var(--color-primary-dark);
  text-align: center;
  font-weight: bold;
`;

const LinksContainer = styled.div`
  flex: 1;
`;
const LinkContainer = styled.div`
  margin-top: 2vh;
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakPoints.bigUnitPx}) {
    margin-top: 0.5em;
  }
`;


const TermsAndConditionsModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <ModalBottom
      isOpen={isModalOpen}
      closeModal={() => setIsModalOpen(false)}
      data-testid='modalComponent'
      height={DimensionsUtils.getValueForBreakpoint([87, 90, 95])}
    >
      <Container>
        <Header>
          <Title>{t('TERMS_CONDITIONS')}</Title>
        </Header>
        <TermsAndConditions />
        <LinksContainer>
          <LinkContainer>
            <Button onClick={() => setIsModalOpen(false)} data-testid="closeModalButton">{t('CLOSE')}</Button>
          </LinkContainer>
        </LinksContainer>
      </Container>
    </ModalBottom >
  );
};

TermsAndConditionsModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  inputValues: PropTypes.object,
};

export default TermsAndConditionsModal;
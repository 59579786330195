import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER,
} from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import rootReducer from './rootStore';
import rootSaga from './rootSaga';

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer(history),
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(sagaMiddleware).concat(routerMiddleware(history)),
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export default store;

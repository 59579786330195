import React from 'react';
import { useSelector } from 'react-redux';
import Header from '../components/Header';
import { getSolutionSelected, getSolutionsToMix } from '../features/recommendations/selectors';
import { t } from '../utils/translations';
import { getUserFullName } from '../features/settings/selectors';

import styled from 'styled-components';
import {isIOS} from 'react-device-detect';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SolutionContainer = styled.div`
  background-color: var(--color-grey);
  min-height: 20vh;
`;

const LabelName = styled.p`
  font-weight: bold;
  color: var(--color-primary);
  text-align: center;
`;

const SolutionsMix = styled.div`
  display: flex;
  flex-direction: column;
`;

const SolutionsNames = styled.p`
  text-align: center;
`;

const SolutionInstructions = styled.p`
  text-aling: center;
  color: var(--color-lightGrey);
  padding: 1.5em;
  margin-top:  var(--min-header-height);

`;

const MixSolutions = () => {
  const userName = useSelector(getUserFullName);
  const solution = useSelector(getSolutionSelected);
  //TODO: get solutions to mix from thunk. If there are no solutions on state, thunk should get them from server
  const solutionsToMix = useSelector(getSolutionsToMix);
  return (
    <Container>
      <Header title={userName} withBack />
      <SolutionContainer style={isIOS?  {paddingTop: '2vh'} : {paddingTop: '0'}}>
        {solution.image &&
          <img width="100%" src={solution.image} />
        }
        <LabelName>{solution.name}</LabelName>
      </SolutionContainer>
      <SolutionsMix>
        {solutionsToMix &&
          <SolutionsNames>
            {solutionsToMix.reduce((title, actual) => {
              const parts = solution.mix.find(m => m.id == actual.id).parts;
              const actualName = `${parts} ${t('PARTS')} ${actual.name}`;
              return title.length ? `${title} + ${actualName}` : actualName;
            }, '')
            }
          </SolutionsNames>}
        <SolutionInstructions>{solution.instructions}</SolutionInstructions>
      </SolutionsMix>
    </Container>
  );
};

export default MixSolutions;
import React, { useEffect } from 'react';
import packageJson from '../../package.json';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getVersionFile } from '../features/versioning/versioningSlice';

global.appVersion = packageJson.version;

export const onHandlerVisibilityChange = async (dispatch) => {
  if (document.visibilityState === 'visible') {
    await dispatch(getVersionFile());
  }
};

export const onHandlerOnFocus = async (dispatch) => {
  if (!document.visibilityState) {
    await dispatch(getVersionFile());
  }
};

const CacheBuster = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => await dispatch(getVersionFile()))();
    const handlerVisibilityChange = () => onHandlerVisibilityChange(dispatch);
    const handlerOnFocus = () => onHandlerOnFocus(dispatch);
    window.addEventListener('visibilitychange', handlerVisibilityChange);
    window.addEventListener('focus', handlerOnFocus);
    document.addEventListener('onvisibilitychange ', handlerVisibilityChange);

    return () => {
      window.removeEventListener('visibilitychange', handlerVisibilityChange);
      window.removeEventListener('focus', handlerOnFocus);
      document.removeEventListener('onvisibilitychange ', handlerVisibilityChange);
    };
  }, []);


  return (
    <>{children}</>
  );
};

CacheBuster.propTypes = {
  children: PropTypes.element,
};

export default CacheBuster;
import { t } from '../utils/translations';

export const WEIGHT_MAX_KG = 5;
export const WEIGHT_MIN_KG = 0;

export const WEIGHT_MAX_G = 9;
export const WEIGHT_MIN_G = 0;

export const AGE_MAX = 41;
export const AGE_MIN = 32;

export const VOLUME_MAX = 200;
export const VOLUME_MIN = 80;

export const types = {
  weight: 'weight',
  age: 'age',
  volume: 'volume',
  solutions: 'solutions',
  hospitalized: 'hospitalized',
  humanMilkAvailable: 'humanMilkAvailable',
  enoughMilk: 'enoughMilk',
};

export const optionsSelectWeight = {
  type: types.weight,
  conditions: [
    {
      conditionUnits: [
        {
          unit: 'kg',
          conditionIndex: 0, //INDEX in values array
          conditionValue: 5,
        }
      ],
      constrainUnits: [
        {
          constrainUnit: 'h_g',
          constrainIndex: 1, //INDEX in values array
          constrainValue: 5
        }
      ]
    },
    {
      conditionUnits: [
        {
          unit: 'kg',
          conditionIndex: 0, //INDEX in values array
          conditionValue: 5,
        },
        {
          unit: 'h_g',
          conditionIndex: 1, //INDEX in values array
          conditionValue: 5,
        }
      ],
      constrainUnits: [
        {
          constrainUnit: 'd_g',
          constrainIndex: 2, //INDEX in values array
          constrainValue: 0
        },
        {
          constrainUnit: 'g',
          constrainIndex: 3, //INDEX in values array
          constrainValue: 0
        }
      ]
    }
  ],
  values: [
    { max: WEIGHT_MAX_KG, min: WEIGHT_MIN_KG, unit: 'kg', unitLabel: 'kg', step: 1 },
    { max: WEIGHT_MAX_G, min: WEIGHT_MIN_G, unit: 'h_g', unitLabel: '', step: 1 },
    { max: WEIGHT_MAX_G, min: WEIGHT_MIN_G, unit: 'd_g', unitLabel: '', step: 1 },
    { max: WEIGHT_MAX_G, min: WEIGHT_MIN_G, unit: 'g', unitLabel: 'g', step: 1 },
  ]
};

export const optionsSelectAge = {
  type: types.age,
  values: [
    { max: AGE_MAX, min: AGE_MIN, unit: 'weeks', unitLabel: 'weeks', step: 1 },
  ]
};

export const optionsSelectVolume = {
  type: types.volume,
  values: [
    { max: VOLUME_MAX, min: VOLUME_MIN, unit: t('UNITS_ML'), unitLabel: t('UNITS_ML'), step: 5 },
  ]
};

const options = [optionsSelectWeight, optionsSelectAge, optionsSelectVolume];

export const getOptionsSelect = (type, values) => {
  const option = options.find(o => o.type === type);
  return option.values.reduce((optionsSelect, value, index) => {
    optionsSelect[value.unit] = [];
    if (value.min > 0)
      optionsSelect[value.unit].push({ value: 0, label: `0 ${value.unitLabel}` });

    let max = value.max;
    if (option.conditions) {
      const unitConditions = option.conditions.filter(condition => condition.constrainUnits.some(cu => cu.constrainUnit === value.unit));
      if (unitConditions) {
        unitConditions.forEach(condition => {
          const constrain = condition.constrainUnits.find(constrain => constrain.constrainUnit === value.unit);
          const limit = condition.conditionUnits.reduce((condition, cUnit) => {
            return condition = condition && values && values[cUnit.conditionIndex] >= cUnit.conditionValue;
          }, true);
          if (limit && index === constrain.constrainIndex) {
            max = constrain.constrainValue;
          }
        });
      }
    }
    for (let i = value.min; i <= max; i += value.step) {
      optionsSelect[value.unit].push({ value: i, label: `${i} ${value.unitLabel}` });
    }
    return optionsSelect;
  }, {});

};

export const getDefaultValues = (type) => {
  return options.find(o => o.type === type).values.reduce((defaultValues, v) => {
    defaultValues[v.unit] = 0;
    return defaultValues;
  }, {});
};
import React, { useEffect, useState } from 'react';
import Button from '../components/ui/Button';
import { Redirect } from 'react-router-dom';
import { t } from '../utils/translations';
import { isIOS } from 'react-device-detect';

import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  width: 90%;
`;

const Label = styled.p`
  color: var(--color-primary);
  font-size: var(--min-font-size);
  text-align: center;
`;

const LabelInstall = styled.p`
  color: var(--color-primary);
  margin: 0;
  text-align: center;
  line-height: 1.5em;
`;

const Row = styled.div`
  display: flex;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  align-items: center;
`;

const Image = styled.img`
  padding-right: 5px;
`;

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const isInStandaloneMode = () => ((('standalone' in window.navigator) && window.navigator.standalone) || window.matchMedia('(display-mode: standalone)').matches);

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('transitionend', handler);
  }, []);

  const onClick = evt => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  const component = isIOS ?
    (<>
      <Row>
        <Image src='./assets/IOSgot.jpg' />
        <LabelInstall>{t('IOS_INSTALL_1')}</LabelInstall>
      </Row>
      <Row>
        <Image src='./assets/IOSadhomescreen.jpg' />
        <LabelInstall>{t('IOS_INSTALL_2')}</LabelInstall>
      </Row>
    </>) :
    <Label>{t('ALREADY_INSTALLED')}</Label>
    ;

  return (isInStandaloneMode() ?
    <Redirect to={{ pathname: '/main' }} /> :
    supportsPWA ?
      <StyledButton
        id="setup_button"
        aria-label="Install web-based platform"
        title="Install web-based platform"
        onClick={onClick}
      >
        {t('INSTALL')}
      </StyledButton> :
      component
  );
};

export default InstallPWA;
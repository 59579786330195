import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButton = styled.button`
  font-family: 'BrandonGrotesqueMedium';
  font-size: var(--header-font-size);
  width: 100%;
  padding: 4px;
  border: 1px solid var(--color-grey);
  border-radius: var(--border-radius);
  color: var(--color-white);
  min-height: 2em;
  ${({ disabled }) => disabled ?
    'background-color: var(--color-grey);' :
    `background-color: var(--color-secondary);
    border: 1px solid var(--color-secondary);`}
  @media (orientation: landscape) {
    height: 1.2em;
  }
`;
const Button = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

Button.propTypes = {
  text: PropTypes.string,
  children: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
/* eslint-disable no-unused-vars */
const medium = 360;
const big = 410;

export const BREAK_POINTS = {
  medium,
  big,
  mediumUnitPx: `${medium}px`,
  bigUnitPx: `${big}px`,
};

export const COLORS = {
  primaryColor: '#30407C',
  primaryColorDark: '#253369',
  primaryColorLight: '#0451AB',
  secondaryColor: '#EE691E',
  secondaryColorBlue: '#009CDE',
  grey: '#E5E5E5',
  greyDark: '#8E8E8E',
  lightGrey: '#C4C4C4',
  lighterGrey: '#F3F3F3',
  blue: '#009CDE',
  white: '#fff',
  black: '#000',
};
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InputStyled = styled.input`
  border-radius: var(--border-radius);
  border: 1px solid var(--color-lightGrey);
  height: var(--input-height);
  outline: none;
  padding: 0;
  padding-left: 5px;
  width: 100%;
`;

const Input = ({ testId, ...props }) => {
  return <InputStyled data-testid={testId} {...props} />;
};

Input.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  className: PropTypes.string,
};

export default Input;
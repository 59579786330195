import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { setUuid } from '../analytics/analyticsSlice';
import { getRecommendations } from './recommendationsSlice';

export function* onGetRecommendations({ payload }) {
  if (payload.uuid)
    yield put(setUuid(payload));
  yield put(push('/output'));
}

export function* afterRejected() {
  yield put(push('/error'));
}

export default function* main() {
  yield takeLatest(getRecommendations.fulfilled, onGetRecommendations);
  yield takeLatest(getRecommendations.rejected, afterRejected);
}
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import TabButtonGroup from '../components/TabButtonGroup';
import HorizontalSlice from '../components/HorizontalSlice';
import GridReference from '../components/GridReference';
import Icon from '../components/Icon';
import { useHistory } from 'react-router-dom';
import { t } from '../utils/translations';
import { useSelector, useDispatch } from 'react-redux';
import TermsAndConditionsModal from '../components/TermsAndConditionsModal';
import {
  setReferenceSelected,
  setSolutionSelected,
  getSolutionsToMix as getSolutionsToMixThunk,
} from '../features/recommendations/recommendationsSlice';
import {
  getFilters,
  getReferencesForTabButtons,
  getReferenceSelected,
  getSolutionsForSlider,
  getSolutionSelected,
  getSolutionsToMix,
}
  from '../features/recommendations/selectors';
import { getUserFullName } from '../features/settings/selectors';

import SummaryModal from '../components/SummaryModal';
import * as DimensionsUtils from '../utils/dimensions';

import styled from 'styled-components';
import { isIOS } from 'react-device-detect';
import { COLORS } from '../constants';

const Container = styled.div`
  display: block;
  background-color: var(--color-lighterGrey);
`;

const LinkContainer = styled.div`
  margin-top: 2vh;
  text-align: center;
`;

const Link = styled.a`
  color: var(--color-primary);
  padding-top: 0;
  cursor: pointer;
`;
const LinkBottom = styled.a`
  color: var(--color-white);
  text-decoration: underline;
  padding-top: 0;
  cursor: pointer;
  font-size: var(--min-font-size);
`;

const InfoContainer = styled.div`
  background-color: var(--color-lighterGrey);
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width:100vw;
  padding-bottom: 8vh;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  background: var(--background-gradient);
  height: 6vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Info = styled.div`
  width: 100vw;
`;
const Accordion = styled.div`
  width: 100vw;
  display: flex;
  background: var(--color-white);
  color: var(--color-primary-dark);
  border: 1px solid var(--color-lightGrey);
  display: flex;
  align-items: center;
  height: var(--min-main-grid-height);
  justify-content: space-between;
`;
const AccordionLabel = styled.span`
  position: absolute;     
  left: 50%;
  transform: translateX(-50%);
  text-decoration: underline;
  font-weight: bold;
`;
const AccordionLabelMix = styled.span`
  position: absolute;     
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  font-size: var(--header-font-size);
  text-decoration: underline;

`;
const Col = styled.div`
  display: flex;
  flex-dirction: row;
  align-items: center;
`;

const LabelClaim = styled.p`
  font-family: 'BrandonGrotesqueRegular';
  color: var(--color-grey-dark);
  font-size: var(--reference-font-size);
  margin:2vh;
  text-align:justify;
  white-space: pre-wrap; 
`;

const MixButtonContainer = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  border: 1px solid var(--color-lightGrey);
  height: 7vh;
  justify-content: center;
`;

const LabelMix = styled.p`
  color: var(--color-primary);
  font-weight: bold;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
`;
const Content = styled.div`
  margin-top:  var(--min-header-height);
`;

export const replaceTextValue = (text) => {
  return text.replace(/\\n/g, '\\\n').replace(/\\t/g, '\\\t').replace(/\\/g, ' ');
};

const Output = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = useSelector(getFilters);
  const buttons = useSelector(getReferencesForTabButtons);
  const referenceSelected = useSelector(getReferenceSelected);
  const sliceItems = useSelector(getSolutionsForSlider);
  const solutionSelected = useSelector(getSolutionSelected);
  const solutionsToMix = useSelector(getSolutionsToMix);
  const userName = useSelector(getUserFullName);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isTCModalOpen, setIsTCModalOpen] = useState(false);
  const [howToMixOpen, setIsHowToMixOpen] = useState(false);
  const [claimsOpen, setClaimsOpen] = useState(false);


  useEffect(() => {
    if (solutionSelected?.mix) dispatch(getSolutionsToMixThunk(solutionSelected.mix.map(s => s.id)));
  }, [solutionSelected]);

  return (
    <Container>
      <Header title={userName} withBack customBack={() => history.push({ pathname: '/main', state: { loadFilters: true } })} />
      <Content style={isIOS ? { paddingTop: '2vh' } : { paddingTop: '0' }}>
        {referenceSelected &&
          <TabButtonGroup buttons={buttons} selected={referenceSelected.id}
            onSelect={(value) => dispatch(setReferenceSelected(value))} />
        }
        <HorizontalSlice items={sliceItems} selected={solutionSelected?.id}
          onSelect={(value) => dispatch(setSolutionSelected(value))} />
        {solutionSelected?.mix &&
          <MixButtonContainer href="#instructions">
            <LabelMix>
              {solutionsToMix.length && solutionsToMix.reduce((title, actual) => {
                const parts = solutionSelected.mix.find(m => m.id == actual.id).parts;
                const actualName = `${parts} ${t('PARTS')} ${actual.name} `;
                return title.length ? `${title} + ${actualName} ` : actualName;
              }, '')
              }
            </LabelMix>
            {solutionSelected.instructions &&
              <LabelMix data-testid="mixButton">
                {`${t('HOW_MIX')}?`}
              </LabelMix>
            }
          </MixButtonContainer>

        }
        {solutionSelected &&
          <GridReference foodValues={solutionSelected} referenceValues={referenceSelected} />
        }
        <SummaryModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} inputValues={filters} />
        <InfoContainer>
          {solutionSelected?.instructions &&
            <Accordion onClick={() => setIsHowToMixOpen(!howToMixOpen)} data-testid="accordionSolutions">
              <Col>
                <AccordionLabelMix>
                  {t('PREPARATION')}
                </AccordionLabelMix>
              </Col>
              <Col style={{ paddingRight: '5px' }}>
                <Icon
                  icon={howToMixOpen ? 'close-accordion' : 'open-accordion'}
                  size={DimensionsUtils.getValueForBreakpoint([25, 15, 10])}
                  color={COLORS.lightGrey} />
              </Col>
            </Accordion>
          }

          {solutionSelected?.instructions && howToMixOpen &&
            <Info id='instructions'>
              <LabelClaim>
                {`${solutionSelected.instructions} `}
              </LabelClaim>
            </Info>
          }
          {solutionSelected?.examples && howToMixOpen &&
            <Info id='examples'>
              <LabelClaim>
                {`${t('EXAMPLES')}: ${solutionSelected.examples} `}
              </LabelClaim>
            </Info>
          }
          {(referenceSelected?.message || referenceSelected?.claims) &&
            <Info>
              <LabelClaim>
                {`* ${referenceSelected?.message || ''} ${referenceSelected?.claims || ''} `}
              </LabelClaim>
            </Info>
          }
          {(solutionSelected?.claims) &&
            <Accordion onClick={() => setClaimsOpen(!claimsOpen)} data-testid='accordionClaims'>
              <Col>
                <AccordionLabel>
                  {`${t('CLAIMS')}`}
                </AccordionLabel>
              </Col>
              <Col style={{ paddingRight: '5px' }}>
                <Icon
                  icon={claimsOpen ? 'close-accordion' : 'open-accordion'}
                  size={DimensionsUtils.getValueForBreakpoint([25, 15, 10])}
                  color={COLORS.lightGrey} />
              </Col>
            </Accordion>
          }
          {solutionSelected?.claims && claimsOpen &&
            <Info>
              <LabelClaim>
                {replaceTextValue(solutionSelected.claims)}
              </LabelClaim>
            </Info>
          }
          {<LinkContainer>
            <Link onClick={() => history.push({ pathname: '/main', state: { loadFilters: false } })} data-testid='goBackInputsLinkOutput'>
              {t('GO_BACK_INPUTS')}
            </Link>
          </LinkContainer>}
          <LinkContainer style={isIOS ? { paddingBottom: '2vh' } : { paddingBottom: '0' }}>
            <Link onClick={() => setIsTCModalOpen(true)} style={{ textDecoration: 'underline' }}>{t('TERMS_CONDITIONS')}</Link>
          </LinkContainer>
        </InfoContainer>
      </Content>
      <Footer style={isIOS ? { paddingBottom: '2vh' } : { paddingBottom: '0' }}>
        <LinkBottom onClick={() => setIsModalOpen(true)}>{t('RETURN_SUMMARY')}</LinkBottom>
      </Footer>
      <TermsAndConditionsModal isModalOpen={isTCModalOpen} setIsModalOpen={setIsTCModalOpen} />
    </Container>
  );
};

export default Output;
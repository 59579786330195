import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SwitchControl from './SwitchControl';

const RowSwitch = styled.div`
  display: flex;
  align-items: center;
  height: var(--min-main-grid-height);
  justify-content: space-between;
  background-color: var(--color-white);
`;

const LabelSwitch = styled.span`
  padding: 5px;
  margin-right: 5px;
  font-size: var(--min-font-size);
  ${({ checked }) => checked ? 'color: var(--color-primary-dark); font-family: BrandonGrotesqueMedium;' : 'color: var(--color-primary);'}
`;

const RowSwitchControl = ({ checked, label, onChange, testId }) => {
  return (
    <RowSwitch>
      <LabelSwitch checked={checked}>{label}</LabelSwitch>
      <SwitchControl
        data-testid={testId}
        onChange={onChange}
        checked={checked}
      />
    </RowSwitch>
  );
};

RowSwitchControl.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  testId: PropTypes.string,
};

export default RowSwitchControl;
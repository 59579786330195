import React from 'react';
import {
  Offline as OfflineCtrl,
  Online as OnlineCtrl,
} from 'react-detect-offline';
import * as Config from '../config';

const healthEndpoint = 'health';
const baseUrl = Config.get('apiUrl');

const pollingUrl = baseUrl.endsWith('/')
  ? `${baseUrl}${healthEndpoint}`
  : `${baseUrl}/${healthEndpoint}`;

export const Offline = (props) => (
  <OfflineCtrl
    {...props}
    polling={{ ...(props && props.polling), url: pollingUrl }}
  />
);
Offline.propTypes = OfflineCtrl.propTypes;

export const Online = (props) => (
  <OnlineCtrl
    {...props}
    polling={{ ...(props && props.polling), url: pollingUrl }}
  />
);
Online.propTypes = OnlineCtrl.propTypes;

// version from response - first param, local version second param
export const compareVersions = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB ? versionB.split(/\./g) : [];
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};
import { put, takeLatest } from 'redux-saga/effects';
import { setTermsAndConditions } from './settingsSlice';
import { push } from 'connected-react-router';

export function* afterAcceptTermsAndConditions() {
  yield put(push('/main'));
}

export default function* main() {
  yield takeLatest(setTermsAndConditions, afterAcceptTermsAndConditions);
}
import React from 'react';
import Header from '../components/Header';
import GridInputs from '../components/GridInputs';
import { t } from '../utils/translations';
import { getUserFullName } from '../features/settings/selectors';
import { getVersion } from '../features/versioning/selectors';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as DimensionsUtils from '../utils/dimensions';
import Icon from '../components/Icon';
import { useDispatch } from 'react-redux';
import { sessionExpired } from '../features/authentication/authenticationSlice';

import styled from 'styled-components';
import { isIOS } from 'react-device-detect';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-lighterGrey);
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  font-family: "BrandonGrotesqueRegular";
  margin-top:  var(--min-header-height);

`;

const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.p`
  font-size: 9vw;
  color: var(--color-secondary-blue);
  margin-left: 2vw;
  margin-bottom: 0;
  margin-top: 1vh;
`;

const VersionLabel = styled.p`
  color: var(--color-white);
  margin-right: 2vw;
  margin-top: 0;
`;

const StyledText = styled.p`
  color: var(--color-black);
  text-align: left;
  margin-left: 2vw;
  margin-top: 0;
`;

const LogoutComponent = styled.div`
  margin-right: 2vw;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LogoutText = styled.p`
  color: var(--color-white);
  margin: 0;
  padding-right: 0.5em;
`;

const Main = () => {
  const location = useLocation();
  const userName = useSelector(getUserFullName);
  const version = useSelector(getVersion);
  const dispatch = useDispatch();

  const rightComponent = <LogoutComponent>
    <Icon
      icon="ICON-logout" size={DimensionsUtils.getValueForBreakpoint([25, 20, 15])}
      color="white"
      onClick={() => dispatch(sessionExpired())}
      data-testid='logoutButton'
    />
    <LogoutText>{t('LOGOUT')}</LogoutText>
  </LogoutComponent>;
  return (
    <Container>
      <Header title={userName} rightComponent={rightComponent} />
      <Content style={isIOS ? { paddingTop: '2vh' } : { paddingTop: '0' }}>
        <Title>{t('INPUTS')}</Title>
        <SubtitleContainer>
          <StyledText>{t('INSERT_VALUES')}</StyledText>
          <VersionLabel>{`v${version}`}</VersionLabel>
        </SubtitleContainer>
        <GridInputs getValuesFromState={location.state && location.state.loadFilters} />
      </Content>
    </Container>);
};


export default Main;

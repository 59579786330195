import React from 'react';
import PropTypes from 'prop-types';
import * as DimensionsUtils from '../utils/dimensions';
import Icon from '../components/Icon';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import {isIOS} from 'react-device-detect';

const Container = styled.div`
  display: flex;
  background:var(--background-header);
  width: 100vw;
  height: var(--min-header-height);
  position:fixed;
  z-index:1;
`;

const SideDiv = styled.div`
  flex-grow: 1;
  min-width: 15%;
  height: 100%;

`;

const LeftDiv = styled(SideDiv)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
`;

const RightDiv = styled(SideDiv)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin:auto;
`;

const CenterDiv = styled.div`
  flex-grow: 1;
  min-width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
`;

const Title = styled.p`
  font-family: "BrandonGrotesqueMedium";
  margin: auto;
  font-size: var(--header-font-size);
  color: var(--color-white);
`;

const LeftContent = styled.div`
  margin-right: '2vw'
`;

const Header = ({ title, withBack, rightComponent, customBack }) => {
  const history = useHistory();
  return (
    <Container style={isIOS?  {paddingTop: '2vh'} : {paddingTop: '0'}}>
      <LeftDiv>
        {withBack &&
          <LeftContent>
            <Icon
              data-testid="backButton"
              icon="chevron-left"
              size={DimensionsUtils.getValueForBreakpoint([40, 30, 20])}
              color="white"
              onClick={() => { customBack ? customBack() : history.goBack(); }} />
          </LeftContent>
        }
      </LeftDiv>
      <CenterDiv>
        <Title>{title}</Title>
      </CenterDiv>
      <RightDiv>
        {rightComponent}
      </RightDiv>
    </Container>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  withBack: PropTypes.bool,
  rightComponent: PropTypes.element,
  customBack: PropTypes.func,
};

export default Header;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { createAppInstalation, logActivity } from '../../services/analytics';
import * as Config from '../../config';

export const SLICE_KEY = 'analytics';

export const verifyLaunchedType = createAsyncThunk(
  `${SLICE_KEY}/verifyLaunchedType`,
  async (isInStandaloneMode, { getState }) => {
    const appInstalation = getState().analytics.appInstalation;
    let uuid = getState().analytics.uuid || null;
    let response;
    if (!appInstalation.synchronized || isInStandaloneMode !== appInstalation.isInStandaloneMode) {
      uuid = uuid ?? uuidv4();
      const regionId = getState().authentication.regionId;
      response = await createAppInstalation({ uuid, type: isInStandaloneMode ? 'stand-alone' : 'browser', regionId });
    }
    return {
      uuid,
      synchronized: response?.status === 201 || appInstalation.synchronized,
      isInStandaloneMode,
    };
  }
);

export const checkLastTimeAction = createAsyncThunk(
  `${SLICE_KEY}/checkLastTimeAction`,
  async (params, { getState }) => {
    const oclockHour = { minute: 0, second: 0, millisecond: 0 };
    const uuid = getState().analytics.uuid;
    let lastTimeAction = getState().analytics.lastTimeAction ? moment(getState().analytics.lastTimeAction).set(oclockHour) : undefined;
    if (!lastTimeAction || moment().set(oclockHour).diff(lastTimeAction, 'minutes') >= parseInt(Config.get('activityLogLapse'))) {
      lastTimeAction = moment().set(oclockHour);
      const regionId = getState().authentication.regionId;
      await logActivity({ uuid, activityDate: lastTimeAction.format(), regionId });
    }
    return {
      lastTimeAction: lastTimeAction.format()
    };
  }
);

export const initialState = {
  uuid: null,
  lastTimeAction: null,
  appInstalation: {
    isInStandaloneMode: false,
    synchronized: false,
  },
  error: null,
};

export const analyticsSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setUuid(state, { payload }) {
      state.uuid = payload.uuid;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(verifyLaunchedType.fulfilled, (state, action) => {
      state.uuid = action.payload.uuid;
      state.appInstalation = {
        isInStandaloneMode: action.payload.isInStandaloneMode,
        synchronized: action.payload.synchronized,
      };
      state.error = null;
    });
    builder.addCase(verifyLaunchedType.rejected, (state, action) => {
      state.error = action.error?.message ?? 'Error checking launched type';
    });
    builder.addCase(checkLastTimeAction.fulfilled, (state, action) => {
      state.lastTimeAction = action.payload.lastTimeAction;

    });
    builder.addCase(checkLastTimeAction.rejected, (state, action) => {
      state.error = action.error?.message ?? 'Error updating last time action';
    });
  }
});

export const { setUuid } = analyticsSlice.actions;

export default analyticsSlice.reducer;
import React from 'react';
import WelcomeBack from '../components/WelcomeBack';
import { getTermsAndConditions } from '../features/settings/selectors';
import { setTermsAndConditions } from '../features/settings/settingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Welcome from '../components/Welcome';

const Init = () => {
  const dispatch = useDispatch();
  const termsAndConditions = useSelector(getTermsAndConditions);
  const history = useHistory();
  return (
    <>
      {!termsAndConditions ?
        <Welcome onAccept={() => dispatch(setTermsAndConditions(true))} /> :
        <WelcomeBack onOk={() => history.push('/main')} />
      }
    </>
  );
};

export default Init;
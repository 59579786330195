import React, { useEffect } from 'react';
import Button from '../components/ui/Button';
import styled from 'styled-components';
import { t } from '../utils/translations';
import { getUserFullName } from '../features/settings/selectors';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { useDispatch } from 'react-redux';
import { setIsLatestVersion, setShouldForceRefresh } from '../features/versioning/versioningSlice';
import { getShouldForceRefresh } from '../features/versioning/selectors';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--color-grey);
  justify-content: flex-end;;
`;

const Content = styled.div`
  width: 100%;
  background: white;
`;

const Title = styled.p`
  font-family: BrandonGrotesqueMedium;
  font-size: var(--min-font-size);
  color: var(--color-primary-dark);
  text-align: center;
  font-weight: bold;
`;

const Text = styled.div`
  margin-top: 5vh;
  margin-bottom: 5vh;
  opacity: 51%;
  padding: 10px;
  text-align: center;
  font-size: var(--min-font-size);

`;

const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5vh;
  font-size: var(--min-font-size);

`;

const StyledButton = styled(Button)`
  background-color: var(--color-primary-dark);
  border: 1px solid var(--color-primary-dark);
  width: 90%;
`;

const StyledSVG = styled(ReactSVG)`
  margin: auto;
`;

const UpdateApp = () => {
  const dispatch = useDispatch();
  const userName = useSelector(getUserFullName);
  const shouldForceRefresh = useSelector(getShouldForceRefresh);

  const refreshCacheAndReload = async () => {
    dispatch(setIsLatestVersion(true));
  };

  useEffect(() => {
    if (shouldForceRefresh) {

      (async () => {
        if (caches) {
          const names = await caches.keys();
          await Promise.all(names.map(name => caches.delete(name)));
        }
        await dispatch(setShouldForceRefresh(false));
        window.location.reload();
      })();
    }
  }, [shouldForceRefresh]);

  return !shouldForceRefresh && (
    <Container>
      <StyledSVG src="iconBlue.svg" />
      <Content>
        <Title>{t('WELCOME_BACK')}</Title>
        <Title>{userName}</Title>
        <Text>
          {t('UPDATE_APP_TEXT')}
        </Text>
        <ContainerButton>
          <StyledButton data-testid='updateBtn' onClick={() => refreshCacheAndReload()}>{t('UPDATE_APP')}</StyledButton>
        </ContainerButton>
      </Content>
    </Container>
  );
};


export default UpdateApp;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalBottom from '../components/ui/ModalBottom';
import { t } from '../utils/translations';
import Icon from '../components/Icon';
import Button from '../components/ui/Button';
import * as DimensionsUtils from '../utils/dimensions';

import Picker from 'rmc-picker/lib/Picker';
import MultiPicker from 'rmc-picker/lib/MultiPicker';
import 'rmc-picker/assets/index.css';

import { COLORS } from '../constants';
import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ disabled }) => disabled && 'pointer-events:none;'}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: var(--min-main-grid-height);
  background-color: var(--color-white);
  justify-content: space-between;
`;

const Col = styled.div`
  display: flex;
  flex-dirction: row;
  align-items: center;
`;

const Label = styled.p`
  padding: 5px;
  margin-right: 5px;
  font-size: var(--min-font-size);
`;

const DefaultLabel = styled(Label)`
  color: var(--color-lightGrey);
  ${({ value }) => value && 'padding-left: 0; color: var(--color-primary);'}
  ${({ optional }) => optional && 'margin-top: 0; margin-bottom: 0'}
`;

const ValueLabel = styled(Label)`
  color: var(--color-primary-dark);
  font-family: BrandonGrotesqueMedium;
  ${({ value }) => value && 'margin-right: 0;'}
  ${({ optional }) => optional && 'padding-top: 0'}
`;

const LabelOptional = styled.p`
  padding-left: 5px;
  font-size: var(--label-font-size);
  color: var(--color-secondary-blue);
  margin: 0;
  position: relative;
  top: -1.3em;
`;

const ContainerModal = styled.div`
  overflow: auto;
  height: 95%;
`;

const HeaderSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LabelDefault = styled.p`
  font-size: var(--min-font-size);
  padding: 0px 15px;
  margin: .5em 1em 0;
  color: ${({ isValue }) => isValue ? 'var(--color-primary)' : 'var(--color-lightGrey)'};
`;

const ContainerPicker = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ContentPicker = styled.div`
  width: 75%;
`;

const StyledButton = styled(Button)`
  background-color: white;
  border-left: none;
  border-right: none;
  color: var(--color-primary);
  border-radius: 2px;
  border-color: var(--color-lightGrey);
  ${({ disabled }) => disabled && 'color: var(--color-grey);'}

`;

export const getLabelsFromValues = (values, options) => {
  const response = {};
  for (let name in options) {
    if (values[name] !== undefined)
      response[name] = options[name].find(g => g.value === values[name])['label'];
  }
  return response;
};

export const getValuesFromGroups = (values, options) => {
  let valueStr = '';
  let hasValue = false;
  for (let props in values) {
    hasValue = hasValue || values[props] > 0;
  }
  if (!hasValue)
    return '';

  const labelsObj = getLabelsFromValues(values, options);
  for (let name in labelsObj) {
    valueStr += ` ${labelsObj[name]}`;
  }
  return valueStr;
};

export const getValues = (valueGroups) => {
  let values = [];
  for (let property in valueGroups) {
    values.push(valueGroups[property]);
  }
  return values;
};

export const getPickers = (optionGroups) => {


  let pickers = [];
  for (let property in optionGroups) {
    pickers.push(optionGroups[property]);
  }


  return pickers;
};

const Select = ({ type, label, optional, customGetValues, noValueSelectedLabel, optionGroups, valueGroups, setFields, disabled = false }) => {
  const getLabelValues = customGetValues || getValuesFromGroups;
  const [values, setValues] = useState(getValues(valueGroups));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [valueLabel, setValueLabel] = useState(getLabelValues(valueGroups, optionGroups));
  const handleChange = (values) => {
    setFields(type, values);
  };
  const IsEnabled = () => {
    return valueLabel !== '';
  };

  useEffect(() => {
    setValueLabel(getLabelValues(valueGroups, optionGroups));
    setValues(getValues(valueGroups));
  }, [valueGroups]);

  useEffect(() => {
    getPickers(optionGroups, type);
  }, []);

  return (
    <>
      <Content disabled={disabled}>
        <Container onClick={() => setIsModalOpen(!isModalOpen)} data-testid="selectContainer">
          <Col >
            {valueLabel &&
              <>
                <ValueLabel value={valueLabel.length > 0} optional={optional}>
                  {valueLabel}
                </ValueLabel>
                {!optional &&
                  <DefaultLabel value={valueLabel}>
                    {' | '}
                  </DefaultLabel>
                }
              </>
            }
            {(!valueLabel || !optional) &&
              <DefaultLabel value={valueLabel} optional={optional}>
                {label}
              </DefaultLabel>
            }
          </Col >
          <Col>
            <Icon
              icon="ICON-right-arrow"
              size={DimensionsUtils.getValueForBreakpoint([25, 15, 10])}
              color={COLORS.lightGrey} />
          </Col>
        </Container >
        {optional && <LabelOptional>{t('OPTIONAL')}</LabelOptional>}
      </Content>

      <ModalBottom
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        data-testid='modalCtrl'
      >
        <ContainerModal data-testid='containerModal'>
          <HeaderSelect>
            <LabelDefault>
              {label}
            </LabelDefault>
            <LabelDefault isValue>
              {valueLabel || noValueSelectedLabel}
            </LabelDefault>
          </HeaderSelect>
          <ContainerPicker >
            <ContentPicker>
              <MultiPicker
                selectedValue={values}
                onValueChange={handleChange}
              >
                {getPickers(optionGroups).map((pickerItems, index) => {
                  return <Picker key={index}>{pickerItems.map(item => {
                    return <Picker.Item key={item.value} value={item.value} className='select-font'>{item.label}</Picker.Item>;
                  })}</Picker>;
                })}
              </MultiPicker>
              <StyledButton onClick={() => setIsModalOpen(false)} disabled={!IsEnabled()} data-testid='selectOkButton'>{t('OK')}</StyledButton>
            </ContentPicker>
          </ContainerPicker>
        </ContainerModal>
      </ModalBottom>
    </>
  );
};

Select.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  valueGroups: PropTypes.object,
  optionGroups: PropTypes.object,
  setFields: PropTypes.func,
  optional: PropTypes.bool,
  customGetValues: PropTypes.func,
  noValueSelectedLabel: PropTypes.string,
  disabled: PropTypes.boolean
};

export default Select;
import axios from 'axios';
import * as Config from '../config';

const apiInstance = axios.create({
  baseURL: Config.get('apiUrl'),
});

export const login = (authToken) => {
  apiInstance.defaults.headers.common['Authorization'] = authToken;
};

export default apiInstance;

const en = {
  HOSPITALIZED: 'Hospitalized',
  HM_AVAILABLE: 'HM available',
  ENOUGH_HM: 'Enough HM volume for 24 hs?',
  WEIGHT: 'Weight',
  VOLUME: 'Fluid volume limit',
  AGE: 'Postconceptional age',
  FEEDING: 'Feeding of interest',
  SUBMIT: 'Submit',
  OPTIONAL: 'Optional',
  UNITS: 'Cal/kg/day',
  UNITS_PROTEIN: 'g/kg/day',
  UNITS_ML: 'ml/kg/day',
  UNITS_MG: 'mg/kg/day',
  SUMMARY: 'Summary',
  YES: 'Yes',
  NO: 'No',
  NONE: 'None',
  RESET_VALUES: 'Reset all inputs',
  WELCOME: 'WELCOME',
  ACCEPT: 'I accept the terms & conditions',
  TERMS_CONDITIONS: 'Terms & Conditions',
  WELCOME_BACK: 'WELCOME BACK!',
  WELCOME_BACK_TEXT: 'We are pleased to have you back as part of our community',
  GO_TO_INPUTS: 'Go to inputs screen',
  NO_MOBILE_ERROR: 'This service is only enabled for use on phones',
  SUMMARY_CONFIRM: 'Confirm if the information submitted is valid',
  OK: 'OK',
  CLOSE: 'Close',
  HOW_MIX: 'How to mix',
  PREPARATION: 'Preparation',
  EXAMPLES: 'Examples',
  CLAIMS: 'Additional information',
  REFERENCE: 'References',
  ENOUGH_MILK: 'Enough HM',
  INSTALL: 'Install',
  TAP: 'Tap on',
  ADD_HOME_SCREEN: 'add to Home Screen',
  ALREADY_INSTALLED: "Please install the web-based platform from browser's menu if you haven't done it already",
  PROTEIN: 'Protein',
  CALCIUM: 'Calcium',
  PHOSPH: 'Phosphorus',
  SORRY: 'Sorry!',
  NO_SOLUTIONS_ALERT: 'There are no results for the information you have entered.',
  NO_SOLUTIONS_ALERT_TRY_AGAIN: 'Please try again.',
  IOS_INSTALL_1: '1. Tap on share button',
  IOS_INSTALL_2: '2. Tap add to Home Screen button',
};
const es = {
  HOSPITALIZED: 'Hospitalizado',
  HM_AVAILABLE: 'LM disponible',
  ENOUGH_HM: '¿Suficiente volumen de leche por 24 hs?',
  WEIGHT: 'Peso',
  VOLUME: 'Buscar volumen límite',
  AGE: 'Edad postconcepcional',
  FEEDING: 'Alimento de interés',
  SUBMIT: 'Enviar',
  OPTIONAL: 'Opcional',
  UNITS: 'Cal/Kg/día',
  UNITS_PROTEIN: 'g/kg/día',
  UNITS_ML: 'ml/kg/día',
  UNITS_MG: 'mg/kg/día',
  SUMMARY: 'Resumen',
  YES: 'Si',
  NO: 'No',
  NONE: 'Ninguna',
  RESET_VALUES: 'Reiniciar valores',
  WELCOME: 'Bienvenido',
  ACCEPT: 'Acepto los terminos y las condicion  es',
  TERMS_CONDITIONS: 'Terminos & Condiciones',
  WELCOME_BACK: 'Bienvenido nuevamente',
  WELCOME_BACK_TEXT: 'Estamos encandos de tenerte de vuelta en nuestra comunidad',
  GO_TO_INPUTS: 'Ir a página de valores',
  NO_MOBILE_ERROR: 'Este servicio esta solo disponible para usarse en teléfonos mobiles',
  SUMMARY_CONFIRM: 'Verifique si la información es válida',
  OK: 'OK',
  CLOSE: 'Cerrar',
  HOW_MIX: '¿Como mezclar?',
  PREPARATION: 'Preparacion',
  EXAMPLES: 'Ejemplos',
  CLAIMS: 'Más información',
  REFERENCE: 'Referencias',
  ENOUGH_MILK: 'Suficiente LH',
  INSTALL: 'Instalar',
  TAP: 'Presione el botón',
  ADD_HOME_SCREEN: 'Agregar a pantalla de inicio',
  ALREADY_INSTALLED: 'Por favor, instale la aplicación desde el menú del navegador si aún no lo ha hecho',
  PROTEIN: 'Proteínas',
  CALCIUM: 'Calcio',
  PHOSPH: 'Fosforo',
  SORRY: '¡Lo sentimos!',
  NO_SOLUTIONS_ALERT: 'No hay resultados para la informacion que ha ingresado. Por favor, intente nuevamente!',
  NO_SOLUTIONS_ALERT_TRY_AGAIN: ' Por favor, intente nuevamente!',
  IOS_INSTALL_1: ' 1. Tap on share button',
  IOS_INSTALL_2: ' 2. Tap add to Home Screen button',
};

export { en, es };
import { BREAK_POINTS } from '../constants';
// const { innerWidth: width, innerHeight: height } = window;
const { innerWidth: width } = window;

export const getValueForBreakpoint = function ([maxValue, midValue, lowValue]) {
  return width >= BREAK_POINTS.big
    ? maxValue
    : width >= BREAK_POINTS.medium
      ? midValue
      : lowValue;
};


import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { connectRouter } from 'connected-react-router';

import storage from 'redux-persist/lib/storage';
import authenticationReducer from '../features/authentication/authenticationSlice';
import settingsReducer from '../features/settings/settingsSlice';
import recommendationsReducer from '../features/recommendations/recommendationsSlice';
import solutionsReducer from '../features/solutions/solutionsSlice';
import versioningReducer from '../features/versioning/versioningSlice';
import inputsReducer from '../features/inputs/inputsSlice';
import analyticsReducer from '../features/analytics/analyticsSlice';

const analyticSPersistConfig = {
  key: 'analytics',
  storage,
  blacklist: ['error'],
};

const authPersistConfig = {
  key: 'authentication',
  storage,
  blacklist: ['loading', 'error'],
};

const settingsPersistConfig = {
  key: 'settings',
  storage,
};

const versioningPersistConfig = {
  key: 'versioning',
  storage,
};

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  analytics: persistReducer(analyticSPersistConfig, analyticsReducer),
  authentication: persistReducer(authPersistConfig, authenticationReducer),
  settings: persistReducer(settingsPersistConfig, settingsReducer),
  versioning: persistReducer(versioningPersistConfig, versioningReducer),
  recommendations: recommendationsReducer,
  inputs: inputsReducer,
  solutions: solutionsReducer,
});

export default rootReducer;

import React from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts/BrandonGrotesque/BrandonGrotesque-Regular.otf';
import './assets/fonts/BrandonGrotesque/BrandonGrotesque-Medium.otf';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register();

import { put, takeLatest } from 'redux-saga/effects';
import { getVersionFile, setIsLatestVersion } from './versioningSlice';
import { push } from 'connected-react-router';

export function* afterGetVersion({ payload }) {
  if (!payload.isLatestVersion)
    yield put(push('/updateApp'));
}

export function* afterSetLastestVersion({ payload }) {
  if (payload)
    yield put(push('/'));
}

export default function* main() {
  yield takeLatest(getVersionFile.fulfilled, afterGetVersion);
  yield takeLatest(setIsLatestVersion, afterSetLastestVersion);
}
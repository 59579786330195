import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../utils/translations';

import styled from 'styled-components';

const Container = styled.div`
  background-color: var(--color-white);
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Column = styled.td`
  padding: 1.5vh 5vw;
  font-size: var(--min-font-size);
  width: 50%;
  color: var(--color-black);
  &:nth-child(1) {
    ${({ isEmptyReference }) => isEmptyReference ? 'display: none;' : ''}
    text-align: left;
    color: var(--color-secondary-blue);
  };
  &:nth-child(2) {
    text-align:  ${({ isEmptyReference }) => isEmptyReference ? 'center' : 'right'};
    color: var(--color-primary); 
  };
`;

const HeaderCol = styled.th`
  text-align: center;
  width: 50%;
  font-family: "BrandonGrotesqueMedium";
  font-weight:bold;
  border-bottom: 2px solid var(--color-primary);


  &:nth-child(1) {
    ${({ isEmptyReference }) => isEmptyReference ? 'display: none;' : ''}
    color: var(--color-secondary-blue);
  };
  &:nth-child(2) {
    color: var(--color-primary);
  };
`;

const Row = styled.tr`
  height: 5vh;
`;

const LabelValue = styled.div`  
  font-family: BrandonGrotesqueMedium;
  font-size: var(--header-font-size);
  `;

const LabelUnits = styled.div`
  color: var(--color-black);
  font-size: var(--label-font-size);
`;

const LabelUnitsSolution = styled.div`
  color: var(--color-primary);
  font-size: var(--label-font-size);
`;

export const getReferenceValues = (values) => {
  if (!values)
    return '';

  if (values.length > 1 && values[0] === values[1]) {
    return values[0].toString();
  }
  return values?.join(' - ');
};

export const getIsEmptyReference = (reference) => {
  const props = ['energy', 'protein', 'calcium', 'phosphorus'];
  return !reference || !props.some(prop => reference[prop]?.length > 0);
};

export const getRowElement = ({ key, isEmptyReference, referenceValue, foodValue, units, solutionUnits }) => {
  return (
    <Row key={key}>
      <Column isEmptyReference={isEmptyReference}>
        <LabelValue>{getReferenceValues(referenceValue)}</LabelValue>
        <LabelUnits>{units}</LabelUnits>
      </Column>
      <Column isEmptyReference={isEmptyReference}>
        <LabelValue>{foodValue}</LabelValue>
        <LabelUnitsSolution>{solutionUnits}</LabelUnitsSolution>
      </Column>
    </Row>
  );
};

export const getElements = ({ isEmptyReference, referenceValues, foodValues }) => {
  const elements = [
    { name: 'energy', units: 'UNITS', solutionUnits: 'UNITS' },
    { name: 'protein', key: 'PROTEIN', units: 'UNITS_PROTEIN' },
    { name: 'calcium', key: 'CALCIUM', units: 'UNITS_MG' },
    { name: 'phosphorus', key: 'PHOSPH', units: 'UNITS_MG' },
  ];

  return elements.map((e, index) =>
    getRowElement({
      key: index,
      isEmptyReference,
      referenceValue: referenceValues ? referenceValues[e.name] : undefined,
      foodValue: foodValues ? foodValues[e.name] : '-',
      units: `${e.key ? t(e.key) : ''} ${t(e.units)}`,
      solutionUnits: `${isEmptyReference && e.key ? t(e.key) : ''} ${t(e.units)}`,
    })
  );
};

const GridReference = ({ referenceValues, foodValues }) => {
  const isEmptyReference = getIsEmptyReference(referenceValues);
  return (
    <Container>
      <Table>
        <thead>
          {(referenceValues || foodValues) &&
            <Row>
              <HeaderCol isEmptyReference={isEmptyReference}>
                {referenceValues ?
                  referenceValues.message ? `${referenceValues.name}*` : referenceValues.name
                  : ''
                }
              </HeaderCol>
              <HeaderCol hideCol={foodValues}>
                {foodValues ? foodValues.name : ''}
              </HeaderCol>
            </Row>
          }
        </thead>
        <tbody>
          {getElements({ isEmptyReference, referenceValues, foodValues })}
        </tbody>
      </Table>
    </Container>
  );
};

GridReference.propTypes = {
  referenceValues: PropTypes.object,
  foodValues: PropTypes.object,
};

export default GridReference;
export const getLanguage = (state) => state.settings.language;

export const getTermsAndConditions = (state) => state.settings.termsAndConditions;

export const getUserFullName = (state) => {
  if (!state.settings.user)
    return '';

  return [state.settings.user.firstName, state.settings.user.lastName].reduce((fullName, name) => {
    return fullName ? fullName + (name ? ` ${name}` : '') : name || '';
  }, '');
};

import { put, takeLatest } from 'redux-saga/effects';
import { login } from './authenticationSlice';
import { push } from 'connected-react-router';
import { setUser } from '../settings/settingsSlice';

export function* afterLogin({ payload }) {
  yield put(setUser(payload.user));
  yield put(push('/init'));
}

export function* onUnknowError({ payload }) {
  if (payload === 'UNEXPECTED_ERROR')
    yield put(push('/error_login'));
}

export default function* main() {
  yield takeLatest(login.fulfilled, afterLogin);
  yield takeLatest(login.rejected, onUnknowError);
}
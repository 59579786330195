import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getIsLoggedIn } from '../features/authentication/selectors';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(getIsLoggedIn);

  return (<Route
    {...rest}
    render={(props) => {
      return (isLoggedIn
        ? (
          <Component {...props} />
        )
        : (
          <Redirect to={{ pathname: '/login', state: { from: props.location.pathname } }} />
        ));
    }}
  />);
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.string,
};

export default PrivateRoute;

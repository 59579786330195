import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import placeHolder from '../assets/placeHolder.png';
import Icon from './Icon';
import * as DimensionsUtils from '../utils/dimensions';
import { t } from '../utils/translations';

const Container = styled.div`
  background-color: var(--color-lighterGrey);
  li.dot{
    background-color: var(--color-secondary-blue)!important;
  }
`;

const Item = styled.div`
  background-color: var(--color-lighterGrey);
  min-height: 20vh;
`;

/*const LabelName = styled.p`
  font-weight: bold;
  color: var(--color-primary);
  text-align: center;
  font-size: var(--product-name-font-size);
  margin: 2vw;
`;*/

const Image = styled.img`
  width: 50%!important;
`;

const PlaceHolder = styled.img`
  width: 50%!important;
  padding: 0.5em;
`;

const AlertContainer = styled.div`
  margin-top: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AlertIcon = styled.div`
  padding: 5px 8px;
  background-color: var(--color-secondary-blue);
  border-radius: 50%;
`;

const ErrorTitle = styled.p`
  margin: 0.3em;
  color: var(--color-black);
  font-family: BrandonGrotesqueMedium;
  font-size: var(--header-font-size);
  opacity: 0.4;
`;

const ErrorMessage = styled.p`
  padding: 15px;
  color: var(--color-black);
  font-family: BrandonGrotesqueRegular;
  font-size: var( --label-font-size);
  margin: 0;
  text-align: center;
  opacity: 0.4;
`;

const HorizontalSlice = ({ items, onSelect }) => {
  return (
    <Container>
      {items?.length ? <Carousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={items.length > 1}
        onChange={(item) => { onSelect(items[item].value); }}
        autoPlay={false}
        interval={3000000}>
        {items && items.map((item) =>
          <Item key={item.value} data-testid='sliceItem' >
            {item.img ?
              <Image src={item.img} /> :
              <PlaceHolder src={placeHolder} />
            }
            {/*
            <LabelName>{item.instructions}</LabelName>
            */}
          </Item>
        )}
      </Carousel> :
        <AlertContainer>
          <AlertIcon>
            <Icon
              icon="ICON-alert"
              size={DimensionsUtils.getValueForBreakpoint([40, 30, 20])}
              color="white" />
          </AlertIcon>
          <ErrorTitle>
            {t('SORRY')}
          </ErrorTitle>
          <ErrorMessage>
            {t('NO_SOLUTIONS_ALERT')}
            <br />
            {t('NO_SOLUTIONS_ALERT_TRY_AGAIN')}
          </ErrorMessage>
        </AlertContainer>
      }
    </Container>
  );
};

HorizontalSlice.propTypes = {
  items: PropTypes.arrayOf(Object).isRequired,
  selected: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default HorizontalSlice;
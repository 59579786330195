import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { compareVersions } from '../../utils/versioning';

export const SLICE_KEY = 'versioning';

export const getVersionFile = createAsyncThunk(
  `${SLICE_KEY}/getVersionFile`,
  async (params, { rejectWithValue, getState }) => {
    try {
      const response = await fetch('/meta.json', { cache: 'no-cache' });
      const metaFile = await response.json();
      const latestVersion = metaFile.version;
      const currentVersion = getState().versioning.version || global.appVersion;
      const shouldForceRefresh = compareVersions(latestVersion, currentVersion);
      return { version: latestVersion, isLatestVersion: !shouldForceRefresh, shouldForceRefresh };
    } catch (error) {
      return rejectWithValue({ version: global.appVersion, isLatestVersion: true, shouldForceRefresh: false, error: 'ERROR_GET_META_FILE' });
    }
  }
);

export const versioningSlice = createSlice({
  name: 'authentication',
  initialState: {
    loading: false,
    shouldForceRefresh: false,
    isLatestVersion: true,
    error: '',
    version: ''
  },
  reducers: {
    setIsLatestVersion: (state, action) => {
      state.isLatestVersion = action.payload;
    },
    setShouldForceRefresh: (state, action) => {
      state.shouldForceRefresh = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getVersionFile.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getVersionFile.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoggedIn = true;
      state.error = '';
      state.version = action.payload.version;
      state.isLatestVersion = action.payload.isLatestVersion;
      state.shouldForceRefresh = action.payload.shouldForceRefresh;
    });
    builder.addCase(getVersionFile.rejected, (state, action) => {
      state.loading = false;
      state.isLoggedIn = true;
      state.error = action.payload.error;
      state.version = action.payload.version;
      state.isLatestVersion = action.payload.isLatestVersion;
    });
  }
});

export const { setVersioning, setIsLatestVersion, setShouldForceRefresh } = versioningSlice.actions;

export default versioningSlice.reducer;

import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Main from './screens/Main';
import Output from './screens/Output';
import PrivateRoute from './components/PrivateRoute';
import Login from './screens/Login';
import MixSolutions from './screens/MixSolutions';
import WebError from './components/WebError';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import store, { persistor, history } from './store';
import { i18nConfig } from './utils/translations';
import { ConnectedRouter } from 'connected-react-router';
import Error from './screens/Error';
import ErrorLogin from './screens/ErrorLogin';
import Init from './screens/Init';
import { ThemeProvider } from 'styled-components';
import { BREAK_POINTS } from './constants';
import GlobalStyles from './GlobalStyles';
import { login } from './services/api';
import { isMobileOnly } from 'react-device-detect';
import CacheBuster from './components/CacheBuster';
import apiInstance from './services/api';
import { sessionExpired } from './features/authentication/authenticationSlice';
import UpdateApp from './screens/UpdateApp';
import { useIdleTimer } from 'react-idle-timer';
import { checkLastTimeAction } from './features/analytics/analyticsSlice';
export const initAxiosInterceptors = () => {
  apiInstance.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response.status === 401 && error.response.request.responseURL.indexOf('authentication') === -1) {
      store.dispatch(sessionExpired());
    }
    return Promise.reject(error);
  });
};

function App() {
  const timeout = 30000;
  const handleOnAction = () => {
    store.dispatch(checkLastTimeAction());
  };

  useIdleTimer({
    timeout,
    events: ['mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'],
    throttle: 5000,
    onAction: handleOnAction,
    crossTab: {
      emitOnAllTabs: true
    }
  });
  const language = store.getState().settings.language;
  useEffect(() => {
    i18nConfig(language);
    login(store.getState().authentication.accessToken);
    initAxiosInterceptors();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.StrictMode>
          <ThemeProvider theme={{ breakPoints: BREAK_POINTS }} >
            <GlobalStyles />
            <CacheBuster>
              {isMobileOnly ?
                <ConnectedRouter history={history} >
                  <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/error" component={Error} />
                    <Route path="/error_login" component={ErrorLogin} />
                    <PrivateRoute path="/updateApp" component={UpdateApp} />
                    <PrivateRoute path="/mixsolutions" component={MixSolutions} />
                    <PrivateRoute path="/output" component={Output} />
                    <PrivateRoute path="/init" component={Init} />
                    <PrivateRoute path="/" component={Main} />
                  </Switch>
                </ConnectedRouter> :
                <WebError />}
            </CacheBuster>
          </ThemeProvider>
        </React.StrictMode>
      </PersistGate>
    </Provider>
  );
}

export default App;

import React from 'react';
import styled from 'styled-components';
import Switch from 'react-switch';
import { COLORS } from '../../constants';

const StyledSwitch = styled(Switch)`
  border: 1px solid ${({ checked }) => checked ? 'var(--color-primary-light)' : 'var(--color-lightGrey)'};
  margin-right: 5px;
`;

const SwitchControl = ({ ...props }) => {
  return <StyledSwitch
    {...props}
    uncheckedIcon={false}
    onColor={COLORS.white}
    offColor={COLORS.white}
    handleDiameter={15}
    offHandleColor={COLORS.lightGrey}
    onHandleColor={COLORS.primaryColorLight}
  />;
};

export default SwitchControl;
import React, { useState } from 'react';
import ModalBottom from '../components/ui/ModalBottom';
import Button from '../components/ui/Button';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { t } from '../utils/translations';
import * as DimensionsUtils from '../utils/dimensions';

import { COLORS } from '../constants';
import styled from 'styled-components';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import Icon from '../components/Icon';



const Container = styled.div`
  display:flex;
  padding: 15px;
  flex-direction: column;
  height: 90%;
  overflow: auto;
`;

const Header = styled.div`
  flex: 2;
`;

const Title = styled.p`
  margin: 0;
  color: var(--color-primary);
  font-size: var(--header-font-size);
`;
const Back = styled.a`
  color: var(--color-primary);
  font-size: var(--min-font-size);
  display: inline-block;
  margin: 0;
`;

const Subtitle = styled.p`
  margin: 0;
  flex: 1;
  color: var(--color-black);
  font-size: var(--min-font-size);
`;

const Table = styled.div`
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px;
  justify-content: space-around;
  margin-bottom: 3vh;
  @media (orientation: landscape) {
    margin-bottom: 0;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.bigUnitPx}) {
    margin-bottom: 0;
  }
`;

const RowLabels = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0;
  margin: 0;
  justify-content: flex-start;
  margin-bottom: 3vh;
  @media (orientation: landscape) {
    margin-bottom: 0;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.bigUnitPx}) {
    margin-bottom: 0;
  }
`;

const Hexagon = styled.div`
  width: 35vw;
  height: 17.5vw;
  ${({ backgroundColor }) => backgroundColor ? `background: ${backgroundColor};` : 'background: var(--color-secondary-blue);'}
  align-self: center;
  position: relative;
  z-index: 1;
  &:before, &:after {
    content:"";
    border-left: 17.6vw solid transparent;
    border-right: 17.6vw solid transparent;
    position: absolute;
  };
  &:before {
    top: -11.8vw;
    border-bottom: 12vw solid ${({ backgroundColor }) => backgroundColor || 'var(--color-secondary-blue)'};
    z-index: 1;
  }
  &:after {
    bottom: -11.8vw;
    border-top: 12vw solid ${({ backgroundColor }) => backgroundColor || 'var(--color-secondary-blue)'};
  }
  @media (orientation: landscape), (max-height: 560px){
    border-radius: 20px;
    &:before, &:after {
      content:"";
      border-left: 0;
      border-right: 0;
      position: absolute;
    };
    &:before {
      border-bottom: 0;
    }
    &:after {
      bottom: 0;
      border-top: 0;
    }
  }
`;

const HexagonInner = styled(Hexagon)`
  background-color: var(--color-white);
  -webkit-transform: scale(.95, .95);
  -moz-transform: scale(.95, .95);
  transform: scale(.95, .95);
  z-index:1;
  &:before {
    border-bottom: 12vw solid var(--color-white);
  }
  &:after {
    border-top: 12vw solid var(--color-white);
  }
  @media (orientation: landscape) {
    &:before {
      border-bottom: 0;
    }
    &:after {
      border-top: 0;
    }
  }
`;

const HexagonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const HexagonValue = styled.p`
  font-weight: bold;
  font-size: var(--min-font-size);
  text-aling: center;
  margin: 0;
  ${({ color }) => color ? `color: ${color};` : 'color: var(--color-white);'}
`;

const HexagonLabel = styled.p`
  text-align: center;
  font-size: var(--min-font-size);
  text-aling: center;
  ${({ color }) => color ? `color: ${color};` : 'color: var(--color-white);'}
  margin: 0;
  z-index: 1;
  
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelTitle = styled.p`
  margin: 0;
  margin-left: 5vw;
  color: var(--color-primary);
  font-size: 1em;
  font-weight: bold;
  font-size: var(--min-font-size);
`;

const Label = styled.p`
  margin: 0;
  margin-left: 5vw;
  margin-bottom: 1.5vh;
  color: var(--color-secondary-blue);
  font-size: var(--min-font-size);
`;

const LinksContainer = styled.div`
  flex: 1;
`;
const LinkContainer = styled.div`
  margin-top: 2vh;
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakPoints.bigUnitPx}) {
    margin-top: 0.5em;
  }
`;

const Link = styled.a`
  color: var(--color-primary);
  cursor: pointer;
  text-decoration: underline;
`;

const SummaryModal = ({ isModalOpen, setIsModalOpen, inputValues }) => {
  const history = useHistory();
  const [isTCModalOpen, setIsTCModalOpen] = useState(false);

  return (
    <ModalBottom
      isOpen={isModalOpen}
      closeModal={() => setIsModalOpen(false)}
      data-testid='modalCtrl'
      height={DimensionsUtils.getValueForBreakpoint([95, 95, 95])}
    >
      <Container>
        <Header>
          <Icon
            icon="chevron-thin-left"
            size={DimensionsUtils.getValueForBreakpoint([15, 15, 10])}
            color={COLORS.primaryColor}
            onClick={() => { history.push({ pathname: '/main', state: { loadFilters: true } }); }}
            data-testid='iconBack' />
          <Back onClick={() => { history.push({ pathname: '/main', state: { loadFilters: true } }); }}>{t('GO_BACK_INPUTS')}</Back>

          <Title>{t('SUMMARY')}</Title>
          <Subtitle>{t('SUMMARY_CONFIRM')}</Subtitle>
        </Header>
        <Table>
          <Row>
            <Hexagon>
              <HexagonInner>
                <HexagonContent>
                  <HexagonValue color={COLORS.secondaryColorBlue}>{inputValues.weight} g</HexagonValue>
                  <HexagonLabel color={COLORS.secondaryColorBlue}>{t('WEIGHT')}</HexagonLabel>
                </HexagonContent>
              </HexagonInner>
            </Hexagon>
            <Hexagon>
              <HexagonContent>
                <HexagonValue>{inputValues.fluidVolumeLimit} {t('UNITS_ML')}</HexagonValue>
                <HexagonLabel>{t('VOLUME')}</HexagonLabel>
              </HexagonContent>
            </Hexagon>
          </Row>
          <Row>
            {inputValues.postConceptionAge > 0 &&
              <Hexagon backgroundColor={COLORS.primaryColor}>
                <HexagonContent>
                  <HexagonValue>{inputValues.postConceptionAge}</HexagonValue>
                  <HexagonLabel>{t('AGE')}</HexagonLabel>
                </HexagonContent>
              </Hexagon>
            }
            <Hexagon backgroundColor={COLORS.primaryColor}>
              <HexagonInner>
                <HexagonContent>
                  {!inputValues.feedingOfInterest && <HexagonValue color={COLORS.primaryColorDark}>{t('NO')}</HexagonValue>}
                  <HexagonLabel color={COLORS.primaryColor}>{inputValues.feedingName || t('FEEDING')}</HexagonLabel>
                </HexagonContent>
              </HexagonInner>
            </Hexagon>
          </Row>
          <RowLabels>
            <Col>
              <LabelTitle>{t('HOSPITALIZED')}</LabelTitle>
              <Label>{t(inputValues.hospitalized ? 'YES' : 'NO')}</Label>
            </Col>
            <Col>
              <LabelTitle>{t('HM_AVAILABLE')}</LabelTitle>
              <Label>{t(inputValues.humanMilkAvailable ? 'YES' : 'NO')}</Label>
            </Col>
            <Col>
              <LabelTitle>{t('ENOUGH_MILK')}</LabelTitle>
              <Label>{t(inputValues.enoughMilk ? 'YES' : 'NO')}</Label>
            </Col>
          </RowLabels>
        </Table>
        <LinksContainer>
          <LinkContainer>
            <Button onClick={() => setIsModalOpen(false)} data-testid="closeModalButton">{t('OK')}</Button>
          </LinkContainer>
          <LinkContainer>
            <Link onClick={() => setIsTCModalOpen(true)}>{t('TERMS_CONDITIONS')}</Link>
          </LinkContainer>
        </LinksContainer>
      </Container>
      <TermsAndConditionsModal isModalOpen={isTCModalOpen} setIsModalOpen={setIsTCModalOpen} />
    </ModalBottom >
  );
};

SummaryModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  inputValues: PropTypes.object,
};

export default SummaryModal;
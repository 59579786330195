export const getFilters = (state) => state.recommendations.filters;

export const getReferencesForTabButtons = (state) => state.recommendations
  .references?.map(r => { return { value: r.id, label: r.name }; });

export const getReferenceSelected = (state) => state.recommendations.referenceSelected;

export const getSolutionsForSlider = (state) => state.recommendations
  .solutions?.map(s => { return { value: s.id, label: s.name, img: s.image, instructions: s.instructions }; });

export const getSolutionSelected = (state) => state.recommendations.solutionSelected;

export const getSolutionsToMix = (state) => state.recommendations.solutionsToMix;

export const getLoading = (state) => state.recommendations.loading;
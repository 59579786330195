import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as authenticationService from '../../services/authentication';
import { verifyLaunchedType } from '../analytics/analyticsSlice';
import { login as loginApi } from '../../services/api';

export const SLICE_KEY = 'authentication';

export const login = createAsyncThunk(
  `${SLICE_KEY}/login`,
  async ({ credentials, isInStandaloneMode }, { dispatch, rejectWithValue }) => {
    try {
      const response = await authenticationService.login(credentials);
      const roles = response.data.user.roles;
      if (roles.includes('admin') || roles.includes('sysAdmin'))
        return rejectWithValue('USER_ROLE_ERROR');
      loginApi(`Bearer ${response.data.accessToken}`);
      dispatch(verifyLaunchedType(isInStandaloneMode));
      return response.data;
    } catch (error) {
      if (error.response)
        switch (error.response.status) {
        case 401:
          return rejectWithValue('USER_PASS_ERROR');
        default:
          return rejectWithValue('UNEXPECTED_ERROR');
        }

      return rejectWithValue('UNEXPECTED_ERROR');
    }
  }
);

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    loading: false,
    isLoggedIn: false,
    accessToken: '',
    error: '',
    regionId: undefined,
  },
  reducers: {
    sessionExpired: (state) => {
      state.isLoggedIn = false;
      state.accessToken = '';
      state.error = 'SESSION_EXPIRED';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoggedIn = true;
      state.accessToken = action.payload.accessToken;
      state.regionId = action.payload.user.regionId;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  }
});

export const { sessionExpired } = authenticationSlice.actions;

export default authenticationSlice.reducer;

import * as Screens from '../../screens/translations';
import * as Components from '../../components/translations';

const en = {
  ...Screens.en,
  ...Components.en,
};

const es = {
  ...Screens.es,
  ...Components.en,
};


export { en, es };

import recommendationsSaga from '../features/recommendations/sagas';
import authenticationSaga from '../features/authentication/sagas';
import settings from '../features/settings/sagas';
import versioning from '../features/versioning/sagas';

import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    recommendationsSaga(),
    authenticationSaga(),
    settings(),
    versioning(),
  ]);
}

export const getWeightFields = (state) => state.inputs.weight;

export const getAgeFields = (state) => state.inputs.age;

export const getVolumeFields = (state) => state.inputs.volume;

export const getSolutionsFields = (state) => state.inputs.solutions;

export const getHospitalized = (state) => state.inputs.hospitalized;

export const getHumanMilkAvailable = (state) => state.inputs.humanMilkAvailable;

export const getEnoughMilk = (state) => state.inputs.enoughMilk;

import React from 'react';
import { Offline as OfflineCtrl, Online } from '../components/Connection';
import { ReactSVG } from 'react-svg';
import { t } from '../utils/translations';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../components/Icon';
import * as DimensionsUtils from '../utils/dimensions';
import * as errorAnimation from '../assets/error_animation.json';
import Lottie from 'react-lottie';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-size: cover;
  background: var(--background-gradient)
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledSVG = styled(ReactSVG)`
  margin-top: 10vh;
  text-align: center;
`;

const ContainerLink = styled.div`
  margin-top: 2vh;
  text-align: center;
`;

const Label = styled.p`
  font-family: "BrandonGrotesqueMedium";
  font-size: 4vw;
  color: var(--color-white);
  margin: 0;
  text-align: center;
  margin-top: 2vh;
`;

const ErrorLogin = () => {
  const history = useHistory();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <Container>
      <LogoContainer>
        <StyledSVG src="icon.svg" />
      </LogoContainer>
      <Label>{t('TITLE_ERROR_MSG')}</Label>
      <OfflineCtrl>
        <StyledSVG src="noConnection.svg" />
        <Label>
          {t('CONNECTION_FAIL')}
        </Label>
      </OfflineCtrl>
      <Online>
        <Lottie options={defaultOptions}
          width={DimensionsUtils.getValueForBreakpoint([400, 300, 200])}
          height={DimensionsUtils.getValueForBreakpoint([400, 300, 200])}
        />
        <Label>
          {t('INTERNAL_ERROR')}
        </Label>
      </Online>
      <ContainerLink>
        <Icon
          data-testid="backButton"
          icon="reload"
          size={DimensionsUtils.getValueForBreakpoint([40, 30, 20])}
          color="white"
          onClick={() => history.goBack()} />
      </ContainerLink>
    </Container>
  );
};


export default ErrorLogin;
